<template>
  <div class="form">
    <div class="form-group">
      <div class="item">
        <FormInput
          v-model="formData.surname.value"
          type="text"
          label="Фамилия"
          size="small"
          placeholder="Введите фамилию"
          :disabled="disabled"
          :error-condition="formData.surname.error"
        />
      </div>
      <div class="item">
        <FormInput
          v-model="formData.firstName.value"
          type="text"
          label="Имя"
          size="small"
          placeholder="Введите Имя"
          :disabled="disabled"
          :error-condition="formData.firstName.error"
        />
      </div>
    </div>
    <div class="form-group-full">
      <div class="item">
        <FormInput
          v-model="formData.secondName.value"
          type="text"
          label="Отчество"
          size="small"
          placeholder="Введите Отчество"
          :disabled="disabled"
          :error-condition="formData.secondName.error"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="item">
        <FormInput
          v-model="formData.email.value"
          type="text"
          label="Email"
          size="small"
          placeholder="Введите Email"
          :disabled="disabled"
          :error-condition="formData.email.error"
        />
      </div>
      <div class="item">
        <FormDate
          v-model="formData.birthDate"
          placeholder="Введите Дату рождения"
          :disabled="disabled"
          label="Дата рождения"
          :error-condition="formData.birthDate.error"
        />
      </div>
    </div>

    <div class="item">
      <FormSelect
        v-model="formData.departmentId.value"
        :items="departments"
        label="Подразделение"
        placeholder="Выберите подразделение"
        :disabled="disabled"
        :error-condition="formData.departmentId.error"
      />
    </div>

    <div class="item">
      <FormSelect
        v-model="formData.positionId.value"
        :items="positions"
        label="Должность"
        placeholder="Выберите должность"
        :disabled="disabled"
        :error-condition="formData.positionId.error"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { DepartmentsAPI } from "@/api/DepartmentsAPI";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";

defineProps({
  disabled: { type: Boolean, default: false },
});

const employeesStore = useEmployeesStore();

const formData = employeesStore.employee;

const departments = ref([]);
const positions = ref([]);

watch(
  () => formData.departmentId.value,
  () => {
    getPositions();
  }
);

const getPositions = () => {
  positions.value = departments.value[0].positions;
};

onMounted(() => {
  DepartmentsAPI.getAll().then((res) => {
    departments.value = res.data;
    if (formData.departmentId.value) {
      positions.value = departments.value[0].positions;
    }
  });
});
</script>

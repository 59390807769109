<template>
  <div class="card w-50">
    <div class="card__header">
      <h2 class="title">Общая информация</h2>
      <div class="card__icons">
        <Button
          icon="pi-pencil"
          variant="outlined"
          color="default"
          @click="rolesStore.toggleEditMode()"
          :active="rolesStore.isEditMode"
        />
        <Button
          icon="pi-trash"
          variant="outlined"
          color="danger"
          @click="rolesStore.setDeleteCatalogModal(true)"
        />
      </div>
    </div>

    <div class="form">
      <FormInput
        type="text"
        label="Отображаемое название раздела"
        size="small"
        placeholder="Введите отображаемое название раздела"
        :disabled="!rolesStore.isEditMode"
        v-model="form.name.value"
        :error-condition="form.name.error"
      />
      <FormInput
        type="text"
        label="Описание раздела"
        size="small"
        placeholder="Введите описание раздела"
        :disabled="!rolesStore.isEditMode"
        v-model="form.description.value"
        :error-condition="form.description.error"
      />

      <div class="form-grid">
        <FormSelect
          label="Владелец раздела"
          placeholder="Выберите владельца раздела"
          :disabled="!rolesStore.isEditMode"
          v-model="form.owner.value"
          :error-condition="form.owner.error"
        />
      </div>

      <label class="form-box">
        <p>Можно запрашивать разрешение у владельца роли</p>
        <Checkbox
          id="qwe"
          v-model="form.canAccessPermission.value"
          :disabled="!rolesStore.isEditMode"
        />
      </label>
    </div>

    <div class="card__header">
      <h2 class="title">Автоматически присвоены все роли из раздела</h2>
    </div>

    <div class="form">
      <RolesBox :mode="rolesStore.isEditMode ? 'edit' : 'view'" />
    </div>

    <div class="card__footer" v-if="rolesStore.isEditMode">
      <Button class="btn-cancel" variant="solid" color="secondary">
        Отмена
      </Button>
      <Button class="btn-accept" @click="editCatalogItem"> Сохранить </Button>
    </div>
  </div>
  <DeleteModal
    :isShowModal="rolesStore.isShowDeleteCatalogModal"
    title="Удалить раздел?"
    @close="rolesStore.setDeleteCatalogModal(false)"
    @delete="deleteCatalogItem"
  />
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import RolesBox from "../../ui/components/RolesBox.vue";
import { useRolesStore } from "../../model/store/useRolesStore";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { useRouter } from "vue-router";
import Checkbox from "@/components/ui/Checkbox/Checkbox.vue";
import { useFormStore } from "../../model/store/useFormStore";

const rolesStore = useRolesStore();
const rolesForm = useFormStore();
const form = rolesForm.formData[1];
const router = useRouter();

const deleteCatalogItem = () => {
  rolesStore.setDeleteCatalogModal(false);
  router.push("/roles");
};

const editCatalogItem = () => {};
</script>

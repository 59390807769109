<template>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Активные роли</h3>
    </div>
    <div class="card__body">
      <Table :columns="activeRolesColumn" :data="activeRoles" :loading="false">
        <template #dropdownActions="{ item }">
          <li @click="revocateRoleHandler(item.id)">Отозвать</li>
          <li @click="reviewRoleHandler(item.id)">Пересмотреть</li>
          <li @click="changeDateModalHandler(item.id)">
            Изменить срок действия
          </li>
        </template>
      </Table>
    </div>
  </div>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Роли в обработке</h3>
    </div>
    <div class="card__body">
      <Table
        :columns="processingRolesColumn"
        :data="processingRoles"
        :loading="loading"
      />
    </div>
  </div>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Архив ролей</h3>
    </div>
    <div class="card__body">
      <Table
        :columns="archiveRolesColumn"
        :data="archiveRoles"
        :loading="loading"
      />
    </div>
  </div>

  <ChangeDate
    :id="currentId"
    @close="employeesStore.setChangeDateModal(false)"
  />
</template>

<script setup>
import Table from "@/components/table/Table.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import ChangeDate from "../components/ChangeDate.vue";
import { ref } from "vue";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";
import {
  activeRolesColumn,
  archiveRolesColumn,
  processingRolesColumn,
} from "../../model/consts/columns";

const employeesStore = useEmployeesStore();
const currentId = ref();

const changeDateModalHandler = (id) => {
  currentId.value = id;
  employeesStore.setChangeDateModal(true);
};

const revocateRoleHandler = (id) => {
  EmployeesAPI.revocateRole(id)
    .then(() => {
      notify({
        type: "success",
        text: "Роль успешно отозвана",
      });
      window.dispatchEvent(new CustomEvent("updateTable"));
    })
    .catch((err) => {
      notifications(err);
    });
};

const reviewRoleHandler = (id) => {
  EmployeesAPI.reviewRole(id)
    .then(() => {
      notify({
        type: "success",
        text: "Роль отправлена на пересмотр",
      });
      window.dispatchEvent(new CustomEvent("updateTable"));
    })
    .catch((err) => {
      notifications(err);
    });
};

defineProps({
  activeRoles: Array,
  processingRoles: Array,
  archiveRoles: Array,
});
</script>

<template>
  <div class="two-factor">
    <div class="two-factor__title">
      Проверка<br />
      безопасности
    </div>
    <p class="two-factor__desc">
      Введите этот 6-значный код <b>{{ localCodeProp }}</b>
    </p>
    <form @submit.prevent="submitCode">
      <div class="two-factor__code">
        <div
          v-for="(field, index) in fields"
          :key="index"
          class="two-factor__number"
        >
          <input
            @input="(event) => handleInput(event, index)"
            @keydown="(event) => handleBackspace(event, index)"
            ref="inputs"
            maxlength="1"
            type="text"
            v-model="fields[index]"
          />
        </div>
      </div>
      <div class="signin__submit">
        <Button
          full
          rounded="sm"
          type="submit"
          :loading="loading"
          :disabled="fields.some((field) => !field)"
          >Подтвердить</Button
        >
        <button v-if="!timer" @click="sendCode" type="button" class="btn_code">
          Получить код повторно
        </button>
        <span v-else>Получить код повторно через {{ timer }} сек.</span>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { notify } from "@kyvg/vue3-notification";
import { AuthAPI } from "@/api/AuthAPI";
import notifications from "@/helpers/notifications";
import { useAuthModule } from "@/store";
import { useRouter } from "vue-router";
import Button from "./ui/Button/Button.vue";

const props = defineProps({
  email: {
    type: String,
    default: "",
  },
  password: {
    type: String,
    default: "",
  },
  code: {
    type: String,
    default: "",
  },
});

const store = useAuthModule();
const router = useRouter();
const localCodeProp = ref(props.code);
const loading = ref(false);
const timer = ref(59);

const fields = ref(Array(6).fill(""));
const inputs = ref([]);
const finalCode = ref("");

const startTimer = () => {
  timer.value = timer.value - 1;
  if (timer.value === 0) {
    clearInterval(counter);
    return;
  }
};

const counter = setInterval(startTimer, 1000);

onMounted(() => {
  if (inputs.value[0]) {
    inputs.value[0].focus();
  }
  notify({
    type: "success",
    text: `Для входа введите этот код ${props.code}`,
  });
  startTimer();
});

const handleInput = (event, index) => {
  const value = event.target.value;
  if (/^\d$/.test(value)) {
    // Проверка, что введено число
    fields.value[index] = value;
    if (index < fields.value.length - 1) {
      inputs.value[index + 1].focus(); // Переключение на следующее поле
    }
  }
  updateFinalCode();
};

const handleBackspace = (event, index) => {
  if (event.key === "Backspace" && !fields.value[index]) {
    if (index > 0) {
      inputs.value[index - 1].focus(); // Переключение на предыдущее поле
    }
  }
  updateFinalCode();
};

// Обновление финального кода
const updateFinalCode = () => {
  finalCode.value = fields.value.join("");
};

watch(
  () => props.code,
  (newCode) => {
    fields.value = newCode.split("").slice(0, 6); // Заполнение полей из пропса
    updateFinalCode();
  }
);

const submitCode = async () => {
  await AuthAPI.confirm_code({
    code: Number(Object.values(finalCode.value).join("")),
    email: props.email,
    password: props.password,
  })
    .then((token) => {
      store.setToken(token);
      router.push("/employees"); //замена с "/"
    })
    .catch((err) => {
      notifications(err);
    });

  await AuthAPI.getProfile().then((res) => {
    store.setUser(res.data);
  });
};

const sendCode = () => {
  timer.value = 59;
  startTimer();
  AuthAPI.login({
    email: props.email,
    password: props.password,
  }).then((response) => {
    localCodeProp.value = response.data.code;
    notify({
      type: "success",
      text: `Мы отправили новый код ${localCodeProp.value}`,
    });
  });
};
</script>

<style scoped lang="scss">
.signin__submit span {
  color: #5bc4d1;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-top: 24px;
}
.btn_code {
  color: #5bc4d1;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-top: 24px;
}
.two-factor {
  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: #727a93;
  }
  &__desc {
    color: #575e76;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 24px;
    b {
      font-weight: 700;
    }
  }
  &__code {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &__number {
    input {
      width: 64px;
      height: 56px;
      outline: none;
      border: 1px solid #d1d8ec;
      border-radius: 6px;
      text-align: center;
      color: #3e445b;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      &:focus {
        border: 1px solid #70e0ee;
        box-shadow: 0px 0px 20px rgba(112, 224, 238, 0.08);
      }
    }
  }
}
@media (max-width: 550px) {
  .two-factor__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }
  .two-factor__code {
    gap: 6px;
  }
  .two-factor__number input {
    width: 46px;
    height: 43px;
  }
}
</style>

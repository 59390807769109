<template>
  <div class="search">
    <svg
      width="24px"
      height="24px"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      class="v-icon"
    >
      <use href="#search" />
    </svg>
    <input
      type="text"
      :value="modelValue"
      @input="onInput"
      :placeholder="placeholder"
    />
  </div>
</template>

<script setup>
defineProps({
  modelValue: String,
  searchValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "Поиск по таблице",
  },
});

const emit = defineEmits(["update:modelValue"]);

const onInput = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  color: #575e76;
  background-color: #fff;
  padding: 14px;
  border-radius: 9px;
  border: 1px solid #ebeef6;
  gap: 10px;
  height: 48px !important;
  input {
    width: 100%;
    border: 0;
    outline: none;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 400;
    font-family: "VelaSans";
    &::placeholder {
      color: #8f97b1;
      font-size: 18px;
      font-family: "VelaSans";
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
    }
  }
}
</style>

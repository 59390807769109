<template>
  <Tabs
    v-model="activeTab"
    :tabs="[
      {
        title: 'Общая информация',
        component: EmployeeInfo,
      },
      {
        title: 'Роли пользователя',
        component: EmployeeRoles,
        props: {
          activeRoles,
          processingRoles,
          archiveRoles,
        },
      },
      {
        title: 'Учетные записи',
        component: EmployeeAccounts,
      },
    ]"
  >
    <template #extra>
      <template v-if="activeTab === 0">
        <Button
          icon="pi-pencil"
          variant="outlined"
          color="default"
          :active="employeesStore.isEditMode"
          @click="employeesStore.toggleEditMode()"
        />
        <!-- <Button
          icon="pi-trash"
          variant="outlined"
          color="danger"
          @click="employeesStore.setDeleteModal(true)"
        /> -->
        <Button
          variant="outlined"
          color="default"
          icon="pi-sort-alt"
          :style="{
            width: '131px',
          }"
          >Перевод</Button
        >
        <FireEmployee />
      </template>

      <template v-if="activeTab === 1">
        <Button
          variant="outlined"
          color="default"
          icon="pi-history"
          @click="updateHandler"
          :style="{
            width: '138px',
          }"
          >Обновить</Button
        >

        <AddRole />
      </template>

      <template v-if="activeTab === 2">
        <Button
          variant="outlined"
          color="default"
          icon="pi-history"
          @click="updateHandler"
          :style="{
            width: '138px',
          }"
          >Обновить</Button
        >
        <AddAccount />
      </template>
    </template>
  </Tabs>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import EmployeeInfo from "./ui/pages/Info.vue";
import EmployeeRoles from "./ui/pages/Roles.vue";
import EmployeeAccounts from "./ui/pages/Accounts.vue";
import { useEmployeesStore } from "./model/store/useEmployeesStore";
import Tabs from "@/components/ui/Tabs/Tabs.vue";
import AddRole from "./ui/components/AddRole.vue";
import AddAccount from "./ui/components/AddAccount.vue";
import { onMounted, onUnmounted, ref } from "vue";
import FireEmployee from "./ui/components/FireEmployee.vue";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useRoute } from "vue-router";
import { USER_STATUSES } from "@/enums/enum-status";

const employeesStore = useEmployeesStore();

const activeTab = ref(0);
const route = useRoute();

const activeRoles = ref([]);
const processingRoles = ref([]);
const archiveRoles = ref([]);

const getEmployee = () => {
  EmployeesAPI.getById(route.params.id).then((res) => {
    const response = res.data;
    activeRoles.value = response.externalRoles;
    processingRoles.value = response.requestExternalRoles.map((role) => ({
      ...role,
      status: USER_STATUSES[role.status],
    }));
    archiveRoles.value = response.historyExternalRoles;

    employeesStore.setEmployee({
      id: response.id,
      surname: response.surname,
      firstName: response.firstName,
      secondName: response.secondName,
      email: response.email,
      positionId: response.position.id,
      birthDate: response.birthday,
      departmentId: response.department.id,
      inactive_status: response.inactive_status,
    });
  });
};

const updateHandler = () => {
  window.dispatchEvent(new CustomEvent("updateTable"));
};

onMounted(() => {
  getEmployee();
  window.addEventListener("updateTable", getEmployee);
});

onUnmounted(() => {
  window.removeEventListener("updateTable", getEmployee);
});
</script>

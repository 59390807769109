<template>
  <div class="form-container">
    <div class="top-line">
      <h3 class="title">Общая информация</h3>
      <div class="buttons">
        <Button
          icon="pi-pencil"
          variant="outlined"
          color="default"
          :active="accountsStore.isEditMode"
          @click="accountsStore.toggleEditMode()"
          v-if="checkEditPermission('providers')"
        />
        <Button
          icon="pi-trash"
          variant="outlined"
          color="danger"
          @click="accountsStore.showDeleteModal()"
          v-if="checkPermission('providers')"
        />
      </div>
    </div>
    <Form :disabled="!accountsStore.isEditMode" />

    <div class="item item--submit" v-if="accountsStore.isEditMode">
      <Button
        size="lg"
        variant="solid"
        color="secondary"
        @click="accountsStore.toggleEditMode()"
      >
        Отмена
      </Button>
      <Button size="lg" @click="edit"> Сохранить </Button>
    </div>

    <DeleteModal
      :isShowModal="accountsStore.isShowDeleteModal"
      title="Удалить учетную запись?"
      @close="accountsStore.hideDeleteModal()"
      @delete="deleteItem"
    />
  </div>
</template>

<script setup>
import Form from "./ui/components/AccountForm.vue";
import { onMounted } from "vue";
import { ref } from "vue";
import { AccountsAPI } from "@/api/AccountsAPI";
import { useRoute, useRouter } from "vue-router";
import notifications from "@/helpers/notifications";
import Button from "@/components/ui/Button/Button.vue";
import { checkEditPermission, checkPermission } from "@/composables/actions";
import { useAccountsStore } from "./model/store/useAccountsStore";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { notify } from "@kyvg/vue3-notification";

const accountsStore = useAccountsStore();

const loading = ref(false);
const route = useRoute();
const router = useRouter();

const edit = () => {
  // handleSubmit({
  //   form: form,
  //   progress: progress.value,
  //   totalRequired: totalRequired.value,
  //   apiMethod: AccountsAPI.update,
  //   successMessage: "Учетная запись успешно изменена",
  //   redirectPath: "/accounts",
  //   id: route.params.id,
  // });
};

const deleteItem = () => {
  AccountsAPI.delete(route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Удалено",
      });

      window.dispatchEvent(new CustomEvent("updateTable"));
      router.push("/accounts");
    })
    .catch((err) => {
      notifications(err);
    })
    .finally(() => {
      accountsStore.hideDeleteModal();
    });
};

onMounted(async () => {
  accountsStore.resetAccount();
  await AccountsAPI.getById(route.params.id)
    .then((res) => {
      const response = res.data;
      accountsStore.setAccount({
        name: response.name,
        serviceId: response.serviceId,
        renewalPeriod: response.renewalPeriod,
        employeeId: response.employeeId,
        active: response.active,
        roles: response.roles,
        password: response.password,
      });
    })
    .catch((err) => {
      notifications(err);
    })
    .finally(() => {
      loading.value = false;
      accountsStore.setAccount({
        name: "Iscra_24_Mob",
        serviceId: 1,
        renewalPeriod: 3,
        employeeId: 1,
        active: false,
        roles: [1],
        password: "asdsad",
      });
    });
});
</script>

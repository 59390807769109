class Status {
  constructor(type, msg, value) {
    this.type = type;
    this.msg = msg;
    this.value = value;
  }

  get message() {
    return this.msg;
  }

  set message(newMsg) {
    this.msg = newMsg;
  }
}

const STATUS_TYPES = {
  success: "success",
  error: "error",
  warning: "warning",
};

export const USER_STATUSES = {
  active: new Status(STATUS_TYPES.success, "Активный", "active"),
  inactive: new Status(STATUS_TYPES.warning, "Заблокирован", "inactive"),
  processing: new Status(STATUS_TYPES.warning, "В обработке", "processing"),
  review: new Status(STATUS_TYPES.warning, "В обработке", "review"),
  onapproval: new Status(STATUS_TYPES.success, "На согласовании", "onapproval"),
};

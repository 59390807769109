import { DefaultAPIInstance } from "@/api";

export const RolesAPI = {
  add(payload) {
    return DefaultAPIInstance.post("/roles/add", payload);
  },

  update(payload, id) {
    return DefaultAPIInstance.put(`/roles/${id}`, payload);
  },

  getAll() {
    return DefaultAPIInstance.get("/external-roles");
  },

  getById(id) {
    return DefaultAPIInstance.get(`/roles/${id}`);
  },
  delete(id) {
    return DefaultAPIInstance.delete(`/roles/${id}`);
  },
};

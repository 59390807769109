<template>
  <div class="tabs">
    <div class="tabs-control">
      <button
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="setActiveTab(index)"
        :class="{ active: index === activeTabIndex }"
      >
        {{ tab.title }}
      </button>
    </div>

    <!-- Слот для дополнительной разметки -->
    <div class="tabs-extra">
      <slot name="extra"></slot>
    </div>
  </div>

  <div class="tab-content">
    <component
      :is="tabs[activeTabIndex].component"
      v-bind="tabs[activeTabIndex].props"
    ></component>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Number,
    default: 0, // Индекс активной вкладки по умолчанию
  },
});
const emit = defineEmits(["update:modelValue"]);

const activeTabIndex = ref(props.modelValue);

const setActiveTab = (index) => {
  activeTabIndex.value = index;
  emit("update:modelValue", index);
};

watch(
  () => props.modelValue,
  (newIndex) => {
    activeTabIndex.value = newIndex;
  }
);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars";

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  &-control {
    display: flex;
    align-items: center;
    gap: 24px;

    button {
      background-color: white;
      border-radius: 8px;
      padding: 10px 16px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.3px;
      color: $theme500;
      cursor: pointer;

      &.active {
        background-color: rgba($primary, 0.08);
        color: $theme900;
      }
    }
  }

  &-extra {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
</style>

<template>
  <Button
    icon="pi-plus"
    @click="employeesStore.setShowAddRolesModal(true)"
    :style="{
      width: '178px',
    }"
    >Назначить роль
  </Button>

  <SideMenu
    @close="closeForm"
    v-if="employeesStore.isShowAddRolesForm"
    title="Добавить роль"
    width="482"
  >
    <template #body>
      <Catalog :roles="roles" @changeSelectedRole="changeSelectedRole" />
    </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        @click="closeForm"
        :style="{
          width: '170px',
        }"
      >
        Отмена
      </Button>
      <Button
        @click="addRole"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore.js";
import Button from "@/components/ui/Button/Button.vue";
import { notify } from "@kyvg/vue3-notification";
import Catalog from "./Catalog.vue";
import { RolesAPI } from "@/api/RolesAPI/index.js";
import { onMounted, ref } from "vue";
import { EmployeesAPI } from "@/api/EmployeesAPI/index.js";
import { useRoute } from "vue-router";
import notifications from "@/helpers/notifications.js";

const employeesStore = useEmployeesStore();
const roles = ref([]);
const route = useRoute();
const selectedRole = ref();

const changeSelectedRole = (id) => {
  selectedRole.value = id;
};

const closeForm = () => {
  employeesStore.setShowAddRolesModal(false);
};

const addRole = () => {
  closeForm();
  EmployeesAPI.addExternalRole(route.params.id, selectedRole.value)
    .then(() => {
      notify({
        type: "success",
        text: "Роль успешно добавлена",
      });
      window.dispatchEvent(new CustomEvent("updateTable"));
    })
    .catch((err) => {
      notifications(err);
    });
};

const getRoles = () => {
  RolesAPI.getAll().then((res) => {
    roles.value = res.data;
  });
};

onMounted(() => {
  getRoles();
});
</script>

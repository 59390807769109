<template>
  <div class="page" :style="{ marginLeft: activeTab === 2 ? '0' : '72px' }">
    <Tabs
      v-model="activeTab"
      :tabs="[
        {
          title: 'Общие настройки',
          component: Settings,
        },
        {
          title: 'Роли',
          component: Roles,
        },
        {
          title: 'Пользователи',
          component: Employees,
        },
      ]"
    />
  </div>
</template>

<script setup>
import Tabs from "@/components/ui/Tabs/Tabs.vue";
import Settings from "./tabs/Settings.vue";
import Roles from "./tabs/Roles.vue";
import Employees from "./tabs/Employees.vue";
import { onMounted, ref } from "vue";
import { RolesAPI } from "@/api/RolesAPI";
import { useRoute } from "vue-router";
import { useFormStore } from "../../../model/store/useFormStore";
import notifications from "@/helpers/notifications";

const activeTab = ref(0);
const route = useRoute();
const rolesForm = useFormStore();

onMounted(async () => {
  await RolesAPI.getById(route.params.id)
    .then((res) => {
      const response = res.data;
      rolesForm.updateStep(1, {
        name: response.name,
        description: response.description,
        owner: response.owner,
        canAccessPermission: response.canAccessPermission,
        roles: response.roles,
      });
    })
    .catch((err) => {
      notifications(err);
    });
  rolesForm.updateStep(1, {
    name: "Администратор сети",
    description: "Роль расширяет полномочия пользователя до администратора ИБ",
    owner: "Куликов Иван Владимирович",
    canAccessPermission: true,
    roles: ["Техническая дирекция"],
  });
});
</script>

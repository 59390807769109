import { DefaultAPIInstance } from "@/api";

export const AccountsAPI = {
  add(payload) {
    return DefaultAPIInstance.post("/accounts", payload);
  },

  update(payload, id) {
    return DefaultAPIInstance.patch(`/accounts/${id}`, payload);
  },

  getAll() {
    return DefaultAPIInstance.get("/accounts");
  },

  getById(id) {
    return DefaultAPIInstance.get(`/accounts/${id}`);
  },

  delete(id) {
    return DefaultAPIInstance.delete(`/accounts/${id}`);
  },

  ban(id) {
    return DefaultAPIInstance.get(`/accounts/ban/${id}`);
  },

  unban(id) {
    return DefaultAPIInstance.get(`/accounts/ban/${id}`);
  },
};

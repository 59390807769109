<template>
  <Modalka
    v-if="employeesStore.isShowChangeDateModal"
    :loading="false"
    actionText="Сохранить"
    @close="$emit('close')"
    @add="changeDateHandler()"
    headerText="Изменить срок действия"
    subtitle="Укажите срок действия назначенной роли"
  >
    <template #body>
      <FormSelect
        v-model="period"
        :items="updatePeriods"
        label="Период"
        placeholder="Укажите период обновления"
      />
      <div class="date-container">
        <FormDate v-model="date[0]" placeholder="с" />
        <span>-</span>
        <FormDate v-model="date[1]" placeholder="по" />
      </div>
    </template>
  </Modalka>
</template>

<script setup>
import { updatePeriods } from "@/enums/enum-accounts";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import Modalka from "@/components/modals/Modalka.vue";
import { ref } from "vue";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

const props = defineProps({
  id: String,
});

const employeesStore = useEmployeesStore();
const period = ref();
const date = ref([]);

const changeDateHandler = () => {
  EmployeesAPI.changeExpiredDate(props.id, date.value[1])
    .then(() => {
      notify({
        type: "success",
        text: "Срок действия роли изменен",
      });
      window.dispatchEvent(new CustomEvent("updateTable"));
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>

<style lang="scss" scoped>
.date-container {
  display: flex;
  gap: 4px;
  align-items: center;

  > * {
    width: 100%;
  }

  span {
    width: 16px;
    height: 16px;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    margin-top: 8px;
  }
}
</style>

<template>
  <Button
    size="sm"
    icon="pi-plus"
    @click="employeesStore.setAddModal(true)"
    :style="{
      width: '254px',
    }"
    >Добавить пользователя
  </Button>

  <SideMenu
    @close="closeForm"
    v-if="employeesStore.isShowAddModal"
    title="Добавить пользователя"
    width="804"
  >
    <template #body> <EmployeeForm :disabled="false" /> </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        @click="closeForm"
        :style="{
          width: '170px',
        }"
      >
        Отмена
      </Button>
      <Button
        @click="addEmployee"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore.js";
import Button from "@/components/ui/Button/Button.vue";
import EmployeeForm from "./EmployeeForm.vue";
import { EmployeesAPI } from "@/api/EmployeesAPI/index.js";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications.js";

const employeesStore = useEmployeesStore();

const closeForm = () => {
  employeesStore.setAddModal(false);
  employeesStore.resetEmployee();
};

const addEmployee = () => {
  if (!employeesStore.validateForm()) {
    return;
  }
  EmployeesAPI.add(employeesStore.getValues)
    .then(() => {
      window.dispatchEvent(new CustomEvent("updateTable"));
      notify({
        type: "success",
        text: "Пользователь успешно добавлен",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>

export const activeRolesColumn = {
  id: { value: "ID" },
  name: { value: "Название роли" },
  authorOfAssignment: { value: "Автор назначения " },
  expiredDate: { value: "Срок действия" },
  assignmentDate: { value: "Дата назначения" },
};

export const processingRolesColumn = {
  id: { value: "ID" },
  name: { value: "Название роли" },
  owner: { value: "Автор назначения " },
  status: { value: "Статус" },
  createAt: { value: "Дата назначения" },
};

export const archiveRolesColumn = {
  id: { value: "ID" },
  name: { value: "Название роли" },
  owner: { value: "Владелец роли" },
  revocationDate: { value: "Дата назначения" },
  revocationAuthor: { value: "Автор назначения " },
  assignmentDate: { value: "Дата отзыва" },
};

export const employeesColumns = {
  id: { value: "ID" },
  surname: { value: "Фамилия " },
  firstName: { value: "Имя " },
  secondName: { value: "Отчество " },
  departmentName: { value: "Подразделение" },
  positionName: { value: "Должность" },
};

<template>
  <div class="sidebar" :class="{ open: isOpen }">
    <div class="sidebar__header">
      <img src="@/assets/images/logo.svg" alt="logo" />
      <!--      <p><span>Service</span> Registry</p>-->
      <p class="bold">CTfind IDM RE</p>
    </div>
    <nav class="menu">
      <ul class="menu__list">
        <template v-for="(item, index) in menu" :key="index">
          <router-link
            class="menu__item"
            :to="item.path"
            v-if="checkPermission(item.permission, item.role)"
            v-bind:class="{
              'router-link-active router-link-exact-active': isRouteActive(
                item.path
              ),
            }"
          >
            <div class="menu__icon">
              <svg
                width="24px"
                role="img"
                height="24px"
                xmlns="http://www.w3.org/2000/svg"
                class="v-icon"
              >
                <use :href="item.icon" />
              </svg>
            </div>
            <span>
              <template v-if="item.path === '/admins'">
                {{
                  [ReesterAdmin, ReesterUser].includes(userRole.id)
                    ? item.title
                    : "Сотрудники"
                }}
              </template>
              <template v-else>
                {{ item.title }}
              </template>
            </span>
          </router-link>
        </template>
      </ul>
      <button class="menu__toggle" type="button" @click="toggle">
        <svg
          width="24px"
          role="img"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
          class="v-icon"
        >
          <use href="#chevron" />
        </svg>
      </button>
      <div class="user-role">Администратор</div>
    </nav>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { UserRoles } from "@/types/Auth";

const route = useRoute();
const { ReesterAdmin, ReesterUser, ProviderAdmin, ProviderUser } = UserRoles;

const menu = [
  {
    title: "Пользователи",
    path: "/employees",
    icon: "#menu-employees",
    role: [ReesterAdmin, ReesterUser, ProviderAdmin, ProviderUser],
  },
  {
    title: "Учетные записи",
    path: "/accounts",
    icon: "#menu-accounts",
    role: [ReesterAdmin, ReesterUser, ProviderAdmin, ProviderUser],
  },
  {
    title: "Управление ролями",
    path: "/roles",
    icon: "#menu-roles",
    role: [ReesterAdmin, ReesterUser, ProviderAdmin, ProviderUser],
  },
];

const checkPermission = () => {
  return true;
};

const isRouteActive = (routePath) => {
  const basePath = `/${route.path.split("/")[1]}`;
  return routePath === basePath;
};

const isOpen = ref(false);

const toggle = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style scoped lang="scss">
.user-role {
  line-height: 28px;
  position: absolute;
  bottom: 21px;
  color: #5bc4d1;
  left: 80px;
  font-size: 18px;
  font-weight: 400;
  width: 254px;
  height: 40px;
  display: flex;
  align-items: center;
  font-family: "VelaSans";
}
.sidebar {
  display: flex;
  flex-direction: column;
  max-width: 80px;
  width: 100%;
  height: 100vh;
  background: #3e445b;
  position: fixed;
  overflow: hidden;
  transition: max-width 0.3s, border-radius 0.3s ease;
  z-index: 99;
  &:hover {
    border-radius: 0px 24px 24px 0px;
    box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.2);
    max-width: 334px;
  }
  &.open {
    border-radius: 0px 24px 24px 0px;
    box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.2);
    max-width: 334px;
    .menu__toggle {
      transform: rotate(-180deg);
    }
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 16px;
    white-space: nowrap;
    img {
      width: 48px;
      height: 48px;
    }
    p {
      margin-left: 24px;
      color: #ffffff;
      font-size: 30px;
      line-height: 32px;
      font-weight: 700;
      font-style: italic;
      span {
        font-weight: 700;
      }
    }
  }
}
.menu {
  margin-top: 25px;
  height: calc(100% - 160px);
  &__toggle {
    background: none;
    border: 0;
    outline: none;
    padding: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 20px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__item {
    height: 64px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 23px;
    color: #ebeef6;
    font-weight: 100;
    text-decoration: none;
    transition: 0.3s;
    border-left: 2px solid transparent;
    white-space: nowrap;
    &:disabled {
      pointer-events: none;
      visibility: hidden;
    }
    &:hover,
    &.active {
      background: rgba(87, 94, 118, 0.32);
      border-left: 2px solid #5bc4d1;
    }
    &.router-link-exact-active {
      background: rgba(87, 94, 118, 0.32);
      border-left: 2px solid #5bc4d1;
    }
    span {
      margin-left: 40px;
      display: block;
      font-size: 18px;
      line-height: 28px;
    }
  }
}
</style>

export const departments = [
  { id: "1", name: "Отдел Информационных Технологий" },
  { id: "2", name: "Бухгалтерия" },
  { id: "3", name: "Управление проектами" },
  { id: "4", name: "Техническая дирекция" },
  { id: "5", name: "Отдел качества" },
  { id: "6", name: "Экономическая безопасность" },
];

export const positions = [
  { id: "1", name: "Администратор" },
  { id: "2", name: "Разработчик" },
  { id: "3", name: "Главный бухгалтер" },
  { id: "4", name: "Бухгалтер" },
  { id: "5", name: "Руководитель отдела" },
  { id: "6", name: "Инженер" },
];

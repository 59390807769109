<template>
  <div class="card">
    <div class="card__header">
      <div class="card__title">
        <h3 class="title">Выберите из списка необходимые роли</h3>
      </div>
    </div>
    <div class="card__body">
      <Catalog :items="roles" :is-editable="true" :disabled="false" />
    </div>

    <div class="card__footer">
      <Button
        class="btn-cancel"
        variant="solid"
        color="secondary"
        @click="formStore.prevStep()"
      >
        Отмена
      </Button>
      <Button class="btn-accept" @click="nextStep()"> Далее </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import Catalog from "@/views/Roles/ui/components/Catalog.vue";
import { roles } from "@/views/Roles/model/consts/catalog";
import { useFormStore } from "@/views/Roles/model/store/useFormStore";

const formStore = useFormStore();

const nextStep = () => {
  formStore.nextStep();
};
</script>

<template>
  <div :class="`status-` + status?.type" class="status">
    {{ status?.msg }}
  </div>
</template>
<script setup>
import { toRefs } from "vue";

const props = defineProps({
  status: {
    default: null,
  },
});
const { status } = toRefs(props);
</script>

<style lang="scss" scoped>
.status {
  position: relative;
  background: #fff2f8;
  width: fit-content;
  border-radius: 5px;
  letter-spacing: 0.3px;
  color: #ef5da8;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 2px 12px;
  padding-left: 20px;
  &:before {
    content: "";
    position: absolute;
    left: 9px;
    top: 11px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #ef5da8;
  }

  &-success {
    background: #eef9f0;
    color: #4cb75d;
    &:before {
      background: #4cb75d;
    }
  }
  &-warning {
    background: #fef7f1;
    color: #f4811f;
    &:before {
      background: #f4811f;
    }
  }
  &-error {
    background: #ffeef0;
    color: #f95266;
    &:before {
      background: #f95266;
    }
  }
}
</style>

<template>
  <div class="header-container">
    <header class="header">
      <div class="header__title">
        <button
          v-if="isAddPage || route.query.name"
          @click="goBack"
          type="button"
        >
          <img src="@/assets/images/back.svg" alt="Назад" />
        </button>
        {{ title }}
      </div>
      <div class="user-control">
        <ul>
          <li class="user-control__notification">
            <button type="button">
              <svg
                width="16px"
                role="img"
                height="20px"
                xmlns="http://www.w3.org/2000/svg"
                class="v-icon"
              >
                <use href="#notify" />
              </svg>
              <span></span>
            </button>
          </li>

          <li class="user-control__profile">
            <button type="button" @click="profilePopup = !profilePopup">
              <img src="@/assets/images/avatar.png" alt="avatar" />
            </button>
            <div
              v-if="profilePopup"
              v-click-outside="onClickOutside"
              class="user-control__popup"
            >
              <div class="user-control-wrap">
                <div class="user-control__user">
                  <img src="@/assets/images/avatar.png" alt="avatar" />
                  <div>
                    <span v-if="user.username !== null">{{
                      user.username
                    }}</span>
                    <span v-if="user.email !== null">{{ user.email }}</span>
                  </div>
                </div>
                <ul class="user-control__menu">
                  <li>
                    <router-link to="/profile">Профиль</router-link>
                  </li>
                  <li>
                    <button @click="logout" type="button">Выйти</button>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script setup>
import { watchEffect, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthModule } from "@/store";
import { hasPermission } from "@/helpers/permissions";

const route = useRoute();
const router = useRouter();
const store = useAuthModule();
const user = computed(() => store.user);
const username = ref();

// Хлебные крошки:
const items = ref([]);
const generateBreadcrumbs = () => {
  // Сбрасываем текущее состояние хлебных крошек
  items.value = [];

  // Получаем базовый путь (первый сегмент URL)
  const basePath = `/${route.path.split("/")[1]}`;

  // Находим маршрут для базового пути
  const baseRoute = router.getRoutes().find((r) => r.path === basePath);

  // Если базовый маршрут найден и у него есть метаданные для хлебных крошек, добавляем его
  if (baseRoute && baseRoute.meta && baseRoute.meta.breadcrumb) {
    items.value.push({
      label: baseRoute.meta.breadcrumb,
      to: baseRoute.path,
    });
  }

  // Добавляем текущий маршрут (если он не является базовым)
  if (route.path !== basePath && route.meta.breadcrumb) {
    items.value.push({
      label: route.meta.breadcrumb,
      to: "",
    });
  }
};
watchEffect(() => {
  generateBreadcrumbs();
});

const goBack = () => {
  router.go(-1);
};

const logout = () => {
  store.logout();
  localStorage.removeItem("token");
  router.push("/signin");
};

const profilePopup = ref(false);

const onClickOutside = () => {
  profilePopup.value = false;
};

const isAddPage = computed(() => route.path.includes("/add"));

let addPermission = ref(false);
watchEffect(() => {
  if (user.value) {
    username.value = user.value?.provider?.name || user.value?.username;
    addPermission.value = hasPermission(
      route.path,
      user.value.permissions,
      ":create"
    );
  }
});

const title = computed(() => route.query.name ?? route.meta.title);
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.header-container {
  grid-column: 2 / 2;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #fff;
  box-shadow: 0px 0px 1px 0px #1317230a, 0px 0px 2px 0px #1317230f,
    0px 3px 6px 0px #1317230a;
}

.header {
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-between;
  box-shadow: 0px 10px 8px -15px rgba(19, 23, 35, 0.04);
  background-color: #fff;
  z-index: 999;
  &__title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: $theme900;
    button {
      padding: 0;
      outline: none;
      border: 1px solid #ebeef6;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      flex-shrink: 0;
      &:hover {
        border: 1px solid #5bc4d1;
      }
    }
  }
}
.user-control {
  &__popup {
    box-shadow: 0px 16px 24px rgb(19 23 35 / 4%), 0px 2px 6px rgb(19 23 35 / 4%),
      0px 0px 1px rgb(19 23 35 / 4%);
    border-radius: 9px;
    max-width: 284px;
    padding: 20px;
    position: absolute;
    right: 0;
    z-index: 9;
    background: #fff;
    top: 55px;
  }
  &__user {
    display: flex;
    align-items: center;
    & > div {
      margin-left: 20px;
      span:first-child {
        font-size: 14px;
        color: #3e445b;
        font-weight: 700;
        display: block;
      }
      span:last-child {
        color: #8f97b1;
        letter-spacing: 0.01em;
        font-size: 12px;
        display: block;
        margin-top: 8px;
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    .user-control__menu {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
      li {
        margin: 0;
        width: 100%;
        a {
          width: 100%;
          display: block;
          color: #364263;
          text-decoration: none;
          padding: 10px 0;
        }
        button {
          width: 100%;
          display: block;
          color: #364263;
          text-decoration: none;
          display: flex;
          padding: 10px 0;
          text-align: left;
          font-size: 16px;

          cursor: pointer;
        }
      }
    }
    li {
      margin: 0 10px;
      position: relative;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__add {
    background: #5bc4d1;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    display: flex;
  }
}

.p-breadcrumb {
  padding: 32px 0;
  margin-left: 67px;
  border: none;
  background: none;
}
</style>

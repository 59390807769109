import { notify } from "@kyvg/vue3-notification";
import { defineStore } from "pinia";

const maxStep = 3;

export const useFormStore = defineStore("add-catalog", {
  state: () => ({
    activeStep: 1,
    formData: {
      1: {
        name: { value: "", error: false, required: true },
        description: { value: "", error: false, required: true },
        owner: { value: "", error: false, required: true },
        canAccessPermission: { value: false, error: false, required: false },
        roles: { value: [], error: false, required: false },
      },
      2: {
        roles: { value: [], error: false, required: false },
      },
      3: {
        employees: {
          value: [
            {
              user: "",
              department: "",
              position: "",
            },
          ],
          error: false,
          required: false,
        },
      },
    },
    unfilledFieldsCount: 0,
  }),
  actions: {
    setActiveStep(payload) {
      this.activeStep = payload;
    },
    updateStep(num, data) {
      const currentStep = this.formData[num];
      for (const key in currentStep) {
        currentStep.value = data[key];
      }
    },
    prevStep() {
      if (this.activeStep === 1) {
        return;
      }
      this.activeStep -= 1;
    },
    nextStep() {
      this.unfilledFieldsCount = 0;
      if (this.activeStep === maxStep) {
        return;
      }
      if (!this.validateStep(this.activeStep)) {
        if (this.unfilledFieldsCount > 0) {
          notify({
            type: "warn",
            text: `Не заполнено ${this.unfilledFieldsCount} пунктов`,
          });
        }
        return;
      }

      this.activeStep += 1;
    },
    validateStep(num) {
      const stepData = this.formData[num];

      // Проходим по полям на указанной странице
      for (const field in stepData) {
        const fieldData = stepData[field];

        // Проверяем, что поле обязательно
        if (fieldData.required && !fieldData.value) {
          // Если value пустое, устанавливаем error в true
          fieldData.error = true;
          this.unfilledFieldsCount += 1;
        } else {
          fieldData.error = false;
        }
      }
      return this.unfilledFieldsCount === 0;
    },
    submitForm() {
      console.log("Form Submitted");
    },
  },
});

<template>
  <div class="card">
    <div class="card__header">
      <div class="card__title">
        <h3 class="title">Каталог ролей</h3>
      </div>
      <div class="card__actions">
        <Button
          icon="pi-plus"
          @click="goToAddPage()"
          :style="{
            width: '202px',
          }"
        >
          Добавить раздел
        </Button>
      </div>
    </div>
    <div class="card__body">
      <Catalog :items="catalog" :is-router="true" />
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import Catalog from "../../components/Catalog.vue";
import { catalog } from "../../../model/consts/catalog";
import { useRouter } from "vue-router";

const router = useRouter();

const goToAddPage = () => {
  router.push("/roles/catalog/add");
};
</script>

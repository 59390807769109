<template>
  <Button
    v-if="filtersData.length > 1"
    icon="#appfilter"
    variant="outlined"
    color="default"
    @click="employeesStore.showFilters()"
    :style="{
      width: '133px',
    }"
    >Фильтры</Button
  >

  <Teleport to="body">
    <AdvancedFilter
      v-show="employeesStore.isShowFilters"
      :filters="filtersData"
      @close="employeesStore.hideFilters()"
      :apply-filters="applyFilters"
      :selectedFilters="selectedFilters"
      @resetFilters="resetFilters"
      @updateFilter="updateSelectedFilters"
    />
  </Teleport>
</template>

<script setup>
import AdvancedFilter from "@/components/table/Filter";
import Button from "@/components/ui/Button/Button.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import { computed } from "vue";
import { ref } from "vue";

const props = defineProps({
  employees: Object,
  updateFilteredItems: Function,
});

const employeesStore = useEmployeesStore();

const filtersData = computed(() => {
  if (!props.employees.length) return [];
  return [
    {
      title: "Подразделение",
      data: [...new Set(props.employees.map((item) => item.department?.name))],
      name: "department",
      type: "checkbox",
      value: [],
    },
    {
      title: "Должность",
      data: [...new Set(props.employees.map((item) => item.position?.name))],
      name: "position",
      type: "checkbox",
      value: [],
    },
  ];
});

const selectedFilters = ref({
  department: [],
  position: [],
});

const updateSelectedFilters = (filterName, option, selectAll = false) => {
  if (selectAll) {
    selectedFilters.value[filterName] = option;
  } else {
    const selected = selectedFilters.value[filterName];
    if (selected.includes(option)) {
      selectedFilters.value[filterName] = selected.filter(
        (item) => item !== option
      );
    } else {
      selectedFilters.value[filterName].push(option);
    }
  }
};

const resetFilters = () => {
  selectedFilters.value = {
    department: [],
    position: [],
  };
};

const applyFilters = () => {
  const filtered = props.employees.filter((item) => {
    return filtersData.value.every((filter) => {
      if (filter.type === "checkbox") {
        return (
          filter.value.length === 0 || filter.value.includes(item[filter.name])
        );
      }
      return true;
    });
  });
  // Вызов метода для обновления filteredItems
  props.updateFilteredItems(filtered);
  employeesStore.hideFilters();
};
</script>

<template>
  <Button
    icon="#appfilter"
    variant="outlined"
    color="default"
    @click="accountsStore.showFilters()"
    :style="{
      width: '133px',
    }"
  >
    Фильтры</Button
  >

  <Teleport to="body">
    <AdvancedFilter
      v-show="accountsStore.isShowFilters"
      :filters="filtersData"
      @close="accountsStore.hideFilters()"
      :apply-filters="applyFilters"
      :selectedFilters="selectedFilters"
      @resetFilters="resetFilters"
      @updateFilter="updateSelectedFilters"
    />
  </Teleport>
</template>

<script setup>
import AdvancedFilter from "@/components/table/Filter";
import Button from "@/components/ui/Button/Button.vue";
import { computed } from "vue";
import { ref } from "vue";
import { useAccountsStore } from "../../model/store/useAccountsStore";
import { USER_STATUSES } from "@/enums/enum-status";

const props = defineProps({
  accounts: Object,
  updateFilteredItems: Function,
});

const accountsStore = useAccountsStore();

const filtersData = computed(() => {
  if (!props.accounts.length) return [];

  return [
    {
      title: "Сервис",
      name: "service",
      type: "checkbox",
      data: [...new Set(props.accounts.map((item) => item.service))],
      value: [],
    },
    {
      title: "Пользователь",
      name: "fio",
      type: "checkbox",
      data: [...new Set(props.accounts.map((item) => item.fio))],
      value: [],
    },
    {
      title: "Дата создания",
      name: "created_at",
      type: "date",
      value: [],
    },
    {
      title: "Дата последнего входа",
      name: "last_login",
      type: "date",
      value: [],
    },
    {
      title: "Дата  последнего обновления",
      name: "updated_at",
      type: "date",
      value: [],
    },
    {
      title: "Статус",
      name: "status",
      type: "checkbox",
      data: [
        ...new Set(
          props.accounts.map((item) => USER_STATUSES[item.status].msg)
        ),
      ],
      value: [],
    },
  ];
});

const selectedFilters = ref({
  service: [],
  fio: [],
  created_at: [],
  last_login: [],
  updated_at: [],
  status: [],
});

const updateSelectedFilters = (filterName, option, selectAll = false) => {
  if (selectAll) {
    selectedFilters.value[filterName] = option;
  } else {
    const selected = selectedFilters.value[filterName];

    if (selected.includes(option)) {
      selectedFilters.value[filterName] = selected.filter(
        (item) => item !== option
      );
    } else {
      console.log(option);
      selectedFilters.value[filterName].push(option);
    }
  }
};

const resetFilters = () => {
  selectedFilters.value = {
    service: [],
    fio: [],
    created_at: [],
    last_login: [],
    updated_at: [],
    status: [],
  };
};

const applyFilters = () => {
  const filtered = props.accounts.filter((item) => {
    return filtersData.value.every((filter) => {
      if (filter.type === "checkbox") {
        const isStatusFilter = filter.name === "status";
        return (
          selectedFilters.value[filter.name].length === 0 ||
          selectedFilters.value[filter.name].includes(
            !isStatusFilter
              ? item[filter.name]
              : USER_STATUSES[item[filter.name]].msg
          )
        );
      } else if (filter.type === "date") {
        const filterDate = selectedFilters.value[filter.name][0];
        return (
          !filterDate ||
          new Date(item[filter.name]).toDateString() ===
            new Date(filterDate).toDateString()
        );
      }
      return true;
    });
  });
  props.updateFilteredItems(filtered);
  accountsStore.hideFilters();
};
</script>

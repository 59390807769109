<template>
  <div class="card w-50">
    <div class="card__header">
      <h2 class="title">Заполните общую информацию</h2>
    </div>

    <div class="form">
      <FormInput
        type="text"
        label="Отображаемое название раздела"
        size="small"
        placeholder="Введите отображаемое название раздела"
        v-model="localModel.name.value"
        :validation="localModel.name"
        :error-condition="localModel.name.error"
      />
      <FormInput
        type="text"
        label="Описание раздела"
        size="small"
        placeholder="Введите описание раздела"
        v-model="localModel.description.value"
        :validation="localModel.description"
        :error-condition="localModel.description.error"
      />

      <div class="form-grid">
        <FormSelect
          label="Владелец раздела"
          placeholder="Выберите владельца раздела"
          :items="usernames"
          v-model="localModel.owner.value"
          :error-condition="localModel.owner.error"
          searchable
        />
      </div>

      <label class="form-box">
        <p>Можно запрашивать разрешение у владельца роли</p>
        <Checkbox id="qwe" v-model="localModel.canAccessPermission.value" />
      </label>
    </div>

    <div class="card__header">
      <h2 class="title">Автоматически присвоить все роли из раздела</h2>
    </div>

    <div class="form">
      <div
        class="form-grid"
        v-for="(item, index) in localModel.roles.value"
        :key="index"
      >
        <FormSelect
          label="Подразделение"
          placeholder="Выберите подразделение"
          :items="departments"
          v-model="item.department"
        />
        <FormSelect
          label="Должность"
          placeholder="Выберите должность"
          :items="positions"
          v-model="item.position"
        />
      </div>
      <Button
        icon="pi-plus"
        variant="outlined"
        class="form-box-button"
        @click="addRole()"
        >Добавить
      </Button>
    </div>

    <div class="card__footer">
      <!-- <Button
        class="btn-cancel"
        variant="solid"
        color="secondary"
        @click="rolesStore.prevStep()"
      >
        Отмена
      </Button> -->
      <Button class="btn-accept" @click="nextStep"> Далее </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import Checkbox from "@/components/ui/Checkbox/Checkbox.vue";
import { usernames } from "@/enums/enum-accounts";
import { departments, positions } from "@/enums/enum-employees";
import { useFormStore } from "@/views/Roles/model/store/useFormStore";
import { reactive } from "vue";

const formStore = useFormStore();

const localModel = reactive({
  name: formStore.formData[1].name,
  description: formStore.formData[1].description,
  owner: formStore.formData[1].owner,
  canAccessPermission: formStore.formData[1].canAccessPermission,
  roles: formStore.formData[1].roles,
});

const addRole = () => {
  localModel.roles.value.push({
    position: "",
    department: "",
  });
};

const nextStep = () => {
  formStore.updateStep(1, localModel);
  formStore.nextStep();
};
</script>

<template>
  <div class="div" @click="toggle">
    <slot name="trigger">
      <i class="pi pi-angle-up" v-if="isToggle" />
      <i class="pi pi-angle-down" v-if="!isToggle" />
    </slot>
  </div>

  <OverlayPanel ref="op">
    <ul class="dropdown">
      <slot name="content" />
    </ul>
  </OverlayPanel>
</template>

<script setup>
import { ref } from "vue";
import OverlayPanel from "primevue/overlaypanel";

const op = ref();
const isToggle = ref(false);
const toggle = (event) => {
  op.value.toggle(event);
  isToggle.value = !isToggle.value;
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars";

.dropdown {
  padding: 8px;
  margin: 0;
  &:deep(li) {
    cursor: pointer;
    transition: 0.3s;
    border-radius: 6px;
    padding: 12px;
    margin: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    &[aria-disabled="true"] {
      color: #d1d8ec;
      pointer-events: none;
      &:hover {
        background: white;
      }
    }
    &:hover {
      background: $theme100;
    }
  }
}
</style>

const columns = {
  id: { value: "ID", sortable: true },
  service: { value: "Сервис", sortable: true },
  account: { value: "Учётная запись", sortable: true },
  fio: { value: "Пользователь", sortable: true },
  created_at: { value: "Дата создания", sortable: true },
  last_login: { value: "Последний вход", sortable: true },
  updated_at: { value: "Обновление", sortable: true },
  status: { value: "Статус", sortable: true },
};

export default columns;

<template>
  <Modalka
    v-if="employeesStore.isShowChangePswdModal"
    :loading="false"
    @close="employeesStore.setShowPswdModal(false)"
    @add="handleSubmitPassword(new_password)"
    actionText="Изменить"
    :headerText="`Изменить пароль`"
  >
    <template v-slot:body>
      <form @submit.prevent="login">
        <div class="password">
          <div class="password__info">
            <span class="password__icon">
              <i class="pi pi-info"></i>
            </span>
            <p class="password__text">
              Доступно внести пароль вручную или сгенерировать автоматически
            </p>
          </div>
          <div class="password__grid">
            <FormInput
              v-model="new_password"
              type="password"
              label="Пароль"
              class="password-block__input grid-input"
            />
            <Button
              variant="outlined"
              color="primary"
              class="grid-button"
              @click="handleGeneratePassword(8)"
              :style="{
                width: '206px',
              }"
              >Сгенерировать</Button
            >
            <FormInput
              v-model="confirm_password"
              type="password"
              label="Пароль"
              class="grid-confirm"
            />
          </div>
        </div>
      </form>
    </template>
  </Modalka>
</template>

<script setup>
import generatePassword from "@/helpers/generatePassword";
import Modalka from "@/components/modals/Modalka.vue";
import Button from "@/components/ui/Button/Button.vue";
import { ref } from "vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";

const props = defineProps({
  password: {
    type: String,
  },
});

const employeesStore = useEmployeesStore();

const new_password = ref(props.password);
const confirm_password = ref("");

const handleGeneratePassword = (length) => {
  const generatedPassword = generatePassword(length);
  new_password.value = generatedPassword;
  confirm_password.value = generatedPassword;
};

const handleSubmitPassword = (password) => {
  console.log(password);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.password {
  &__info {
    padding: 24px 16px;
    background-color: #f1fdff;
    border: 1px solid $theme100;
    margin-bottom: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      color: white;
      i {
        transform: rotate(180deg);
      }
    }
    p {
      color: $theme800;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
    gap: 24px;

    .grid-input {
      grid-column: 1 / 3;
      margin-bottom: 0;
    }
    .grid-button {
      grid-column: 3 / 4;
      margin-bottom: 4px;
    }
    .grid-confirm {
      grid-column: 1 / 4;
    }
  }
}
</style>

<template>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Учетные записи</h3>
    </div>
    <div class="card__body">
      <Table :columns="accountsColumn" :data="accounts" :loading="loading">
        <template #dropdownActions="{ item }">
          <li
            @click="employeesStore.setShowActivateAccount('activate')"
            :aria-disabled="item.status.value === 'active'"
          >
            Разблокировать
          </li>
          <li
            @click="employeesStore.setShowActivateAccount('deactivate')"
            :aria-disabled="item.status.value === 'inactive'"
          >
            Заблокировать
          </li>
          <li @click="employeesStore.setShowPswdModal(true)">
            Изменить пароль
          </li>
        </template>
      </Table>
    </div>
  </div>

  <AccountActivation />
  <ChangePassword password="password" />
</template>

<script setup>
import Table from "@/components/table/Table.vue";
import { ref } from "vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import AccountActivation from "../components/AccountActivation.vue";
import ChangePassword from "../components/ChangePassword.vue";

const employeesStore = useEmployeesStore();

const accounts = ref([]);

const accountsColumn = {
  id: { value: "ID" },
  name: { value: "Наименование" },
  service: { value: "Сервис" },
  date: { value: "Дата создания" },
  lastLogin: { value: "Последний вход" },
  status: { value: "Статус" },
};
</script>

import { notify } from "@kyvg/vue3-notification";
import { defineStore } from "pinia";

export const useEmployeesStore = defineStore("employees", {
  state: () => ({
    isShowDeleteModal: false,
    isEditMode: false,
    isShowAddModal: false,
    isShowFilters: false,
    isShowFiredModal: false,
    isShowAddRolesForm: false,
    isShowAddAccountForm: false,
    isShowChangeDateModal: false,
    isShowActivateAccount: null,
    isShowChangePswdModal: false,
    employee: {
      id: { value: "", error: false, required: false },
      surname: { value: "", error: false, required: true },
      firstName: { value: "", error: false, required: true },
      secondName: { value: "", error: false, required: true },
      email: { value: "", error: false, required: true },
      positionId: { value: "", error: false, required: true },
      departmentId: { value: "", error: false, required: false },
      birthDate: { value: "", error: false, required: true },
      inactive_status: { value: false, error: false, required: false },
    },
  }),
  getters: {
    getValues(state) {
      const values = {};
      for (const key in state.employee) {
        if (state.employee[key].required) {
          values[key] = state.employee[key].value;
        }
      }
      return values;
    },
    getFullName(state) {
      return `${state.employee.surname.value} ${state.employee.firstName.value} ${state.employee.secondName.value}`;
    },
  },
  actions: {
    setShowPswdModal(payload) {
      this.isShowChangePswdModal = payload;
    },
    setChangeDateModal(payload) {
      this.isShowChangeDateModal = payload;
    },
    setShowActivateAccount(payload) {
      this.isShowActivateAccount = payload;
    },
    setShowAddRolesModal(payload) {
      this.isShowAddRolesForm = payload;
    },
    setShowAddAccountForm(payload) {
      this.isShowAddAccountForm = payload;
    },
    setDeleteModal(payload) {
      this.isShowDeleteModal = payload;
    },
    setEditMode(payload) {
      this.isEditMode = payload;
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    setAddModal(payload) {
      this.isShowAddModal = payload;
    },
    showFilters() {
      this.isShowFilters = true;
    },
    hideFilters() {
      this.isShowFilters = false;
    },
    showFiredModal() {
      this.isShowFiredModal = true;
    },
    hideFiredModal() {
      this.isShowFiredModal = false;
    },
    setEmployee(payload) {
      for (const key in payload) {
        this.employee[key].value = payload[key];
      }
    },
    resetEmployee() {
      for (const key in this.employee) {
        this.employee[key].value = "";
        this.employee[key].error = false;
      }
    },
    validateForm() {
      let unfilledItems = 0;
      const formData = this.employee;

      for (const field in formData) {
        const fieldData = formData[field];

        if (fieldData.required && !fieldData.value) {
          fieldData.error = true;
          unfilledItems += 1;
        } else {
          fieldData.error = false;
        }
      }
      if (unfilledItems !== 0) {
        notify({
          type: "warn",
          text: `Не заполнено ${unfilledItems} пунктов`,
        });
        return false;
      }
      return true;
    },
  },
});

<template>
  <div class="card w-50">
    <div class="card__header">
      <h2 class="title">
        Укажите данные пользователя для присвоения всех ролей из раздела
      </h2>
    </div>

    <div class="card__body">
      <div
        class="form"
        v-for="(item, index) in localModel.employees.value"
        :key="index"
      >
        <FormSelect
          label="Пользователь"
          placeholder="Выберите пользователя"
          :items="usernames"
          v-model="item.employee"
          searchable
        />

        <div class="form-grid">
          <FormSelect
            label="Подразделение"
            placeholder="Выберите подразделение"
            :items="departments"
            v-model="item.department"
          />
          <FormSelect
            label="Должность"
            placeholder="Выберите должность"
            :items="positions"
            v-model="item.position"
          />
        </div>
      </div>

      <Button icon="pi-plus" variant="outlined" @click="addEmployee()"
        >Добавить
      </Button>
    </div>

    <div class="card__footer">
      <Button
        class="btn-cancel"
        variant="solid"
        color="secondary"
        @click="formStore.prevStep()"
      >
        Отмена
      </Button>
      <Button class="btn-accept" @click="addCatalogItem()"> Добавить </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import { useRouter } from "vue-router";
import { usernames } from "@/enums/enum-accounts";
import { departments, positions } from "@/enums/enum-employees";
import { useFormStore } from "@/views/Roles/model/store/useFormStore";
import { reactive } from "vue";

const router = useRouter();

const formStore = useFormStore();

const localModel = reactive({
  employees: formStore.formData[3].employees,
});

const addEmployee = () => {
  localModel.employees.value.push({
    employee: "",
    department: "",
    position: "",
  });
};

const addCatalogItem = () => {
  formStore.updateStep(3, localModel);
  formStore.submitForm();
  router.push("/roles");
};
</script>

<template>
  <Button
    icon="#appfilter"
    variant="outlined"
    color="default"
    @click="rolesStore.setFilters(true)"
    :style="{
      width: '133px',
    }"
  >
    Фильтры</Button
  >

  <Teleport to="body">
    <AdvancedFilter
      v-show="rolesStore.isShowFilters"
      :filters="filtersData"
      @close="rolesStore.setFilters(false)"
      :apply-filters="applyFilters"
      :selectedFilters="selectedFilters"
      @resetFilters="resetFilters"
      @updateFilter="updateSelectedFilters"
    />
  </Teleport>
</template>

<script setup>
import AdvancedFilter from "@/components/table/Filter";
import Button from "@/components/ui/Button/Button.vue";
import { computed } from "vue";
import { ref } from "vue";
import { USER_STATUSES } from "@/enums/enum-status";
import { useRolesStore } from "../../model/store/useRolesStore";

const props = defineProps({
  roles: Object,
  updateFilteredItems: Function,
});

const rolesStore = useRolesStore();

const filtersData = computed(() => {
  if (!props.roles.length) return [];

  return [
    {
      title: "Название роли",
      name: "name",
      type: "checkbox",
      data: [...new Set(props.roles.map((item) => item.name))],
      value: [],
    },
    {
      title: "Источник",
      name: "source",
      type: "checkbox",
      data: [...new Set(props.roles.map((item) => item.source))],
      value: [],
    },
  ];
});

const selectedFilters = ref({
  name: [],
  source: [],
});

const updateSelectedFilters = (filterName, option, selectAll = false) => {
  if (selectAll) {
    selectedFilters.value[filterName] = option;
  } else {
    const selected = selectedFilters.value[filterName];
    if (selected.includes(option)) {
      selectedFilters.value[filterName] = selected.filter(
        (item) => item !== option
      );
    } else {
      selectedFilters.value[filterName].push(option);
    }
  }
};

const resetFilters = () => {
  selectedFilters.value = {
    name: [],
    source: [],
  };
};

const applyFilters = () => {
  const filtered = props.roles.filter((item) => {
    return filtersData.value.every((filter) => {
      if (filter.type === "checkbox") {
        const isStatusFilter = filter.name === "status";
        return (
          selectedFilters.value[filter.name].length === 0 ||
          selectedFilters.value[filter.name].includes(
            !isStatusFilter
              ? item[filter.name]
              : USER_STATUSES[item[filter.name]].msg
          )
        );
      } else if (filter.type === "date") {
        const filterDate = selectedFilters.value[filter.name][0];
        return (
          !filterDate ||
          new Date(item[filter.name]).toDateString() ===
            new Date(filterDate).toDateString()
        );
      }
      return true;
    });
  });
  props.updateFilteredItems(filtered);
  rolesStore.setFilters(false);
};
</script>

<template>
  <button
    :class="[
      'btn',
      variantClass,
      roundedClass,
      sizeClass,
      colorClass,
      { 'btn-icon-only': isIconOnly },
      { 'btn-active': active },
      { 'btn-full': full },
      // { 'btn-loading': loading },
      className,
    ]"
    :disabled="loading"
    v-bind="attrs"
  >
    <template v-if="icon">
      <template v-if="isSvgIcon">
        <svg
          width="24px"
          height="24px"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          class="app-icon"
        >
          <use :href="icon" />
        </svg>
      </template>
      <template v-else>
        <i :class="`pi ${icon}`"></i>
      </template>
    </template>
    <slot></slot>
  </button>
</template>

<script setup>
import { computed, useSlots } from "vue";
import { useAttrs } from "vue";

const props = defineProps({
  variant: {
    type: String,
    default: "solid",
    validator: (value) => ["solid", "outlined"].includes(value),
  },
  color: {
    type: String,
    default: "primary",
    validator: (value) =>
      ["default", "secondary", "primary", "danger"].includes(value),
  },
  rounded: {
    type: String,
    default: "full",
    validator: (value) => ["sm", "full"].includes(value),
  },
  size: {
    type: String,
    default: "none",
    validator: (value) => ["none", "sm", "md", "lg"].includes(value),
  },
  active: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: null,
  },
  className: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  full: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs(); // Для передачи всех остальных атрибутов кнопки
const slots = useSlots(); // Доступ к слотам

const variantClass = computed(() => {
  return {
    solid: "btn-solid",
    outlined: "btn-outlined",
  }[props.variant];
});

const colorClass = computed(() => {
  return {
    primary: "btn-primary",
    secondary: "btn-secondary",
    default: "btn-default",
    danger: "btn-danger",
  }[props.color];
});

const roundedClass = computed(() => {
  return {
    sm: "btn-rounded-sm",
    full: "btn-rounded-full",
  }[props.rounded];
});

const sizeClass = computed(() => {
  return {
    none: "btn-no-padding",
    sm: "btn-sm",
    md: "btn-md",
    lg: "btn-lg",
  }[props.size];
});

// Проверка, есть ли текстовый контент внутри кнопки
const isIconOnly = computed(() => !slots.default);

const isSvgIcon = computed(() => props.icon && props.icon.startsWith("#"));
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary;
  cursor: pointer;
  font-size: 16px;
  color: $theme800;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: background-color 0.25s ease, border-color 0.25s ease,
    color 0.25s ease;

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-solid {
  &.btn-primary {
    background-color: $primary;
    color: white;

    &:disabled {
      background-color: #575e760a;
      border-color: #575e760a;
      color: $theme500;
    }

    &:hover {
      background-color: lighten($primary, 5%);
      border-color: lighten($primary, 5%);

      &:disabled {
        background-color: #575e760a;
        border-color: #575e760a;
        color: $theme500;
      }
    }
  }

  &.btn-secondary {
    background-color: #575e760a;
    border-color: #575e760a;

    &:hover {
      background-color: lighten(#575e760a, 5%);
      border-color: lighten(#575e760a, 5%);
    }
  }
  &.btn-danger {
    background-color: $danger;
    border-color: $danger;
    color: white;
    &:hover {
      background-color: lighten($danger, 5%);
      border-color: lighten($danger, 5%);
    }
  }
}

.btn-outlined {
  background-color: white;
  border-color: $theme200;

  &.btn-default {
    color: $theme700;

    &.btn-active {
      border-color: $primary;
      color: $primary;
    }

    &:hover {
      border-color: $primary;
      color: $primary;
    }
  }

  &.btn-primary {
    color: $primary;
    border-color: $primary;

    &:disabled {
      border-color: $theme300;
      color: $theme300;
      cursor: not-allowed;

      &:hover {
        border-color: $theme300;
      }
    }

    &:hover {
      border-color: lighten($primary, 5%);
    }
  }

  &.btn-secondary {
    border-color: #575e760a;

    &:hover {
      background-color: lighten(#575e760a, 5%);
      border-color: lighten(#575e760a, 5%);
    }
  }
  &.btn-danger {
    border-color: $theme200;
    color: $danger;
    &:hover {
      border-color: lighten($danger, 5%);
    }
  }
}

.btn-rounded-sm {
  border-radius: 9px;
}

.btn-rounded-full {
  border-radius: 48px;
}

.btn-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.btn-sm {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-md {
  padding-left: 30px;
  padding-right: 30px;
}

.btn-lg {
  padding-left: 50px;
  padding-right: 50px;
}

.btn-full {
  width: 100%;
}

.pi,
.app-icon {
  margin-right: 8px;
}

.btn-icon-only {
  /* Делаем кнопку квадратной, если только иконка */
  width: 48px;
  height: 48px;
  aspect-ratio: 1 / 1;
  padding: 0;
  .pi {
    margin-right: 0;
  }
  &.btn-sm {
    width: 36px;
    height: 36px;
  }
}

/* .btn-loading {
  background-color: #eef9fa;
} */
</style>

<template>
  <div class="divider"></div>
  <div class="form-row">
    <div class="item">
      <FormInput
        v-model="formData.name.value"
        type="text"
        label="Наименование учетной записи"
        size="small"
        placeholder="Введите наименование учетной записи"
        :disabled="disabled"
        :error-condition="accountsStore.account.name.error"
      />
    </div>

    <div class="item">
      <FormSelect
        v-model="formData.serviceId.value"
        :items="services"
        label="Сервис"
        placeholder="Выберите сервис"
        :disabled="disabled"
        :error-condition="formData.serviceId.error"
        searchable
      />
    </div>
  </div>
  <div class="form-row">
    <div class="item">
      <FormSelect
        v-model="formData.renewalPeriod.value"
        :items="updatePeriods"
        label="Укажите период обновления"
        placeholder="Укажите период обновления"
        :disabled="disabled"
        :error-condition="formData.renewalPeriod.error"
      />
    </div>

    <div class="item">
      <FormSelect
        v-model="formData.employeeId.value"
        :items="usernames"
        label="ФИО Пользователя"
        placeholder="Укажите ФИО Пользователя"
        :disabled="disabled"
        :error-condition="formData.employeeId.error"
        searchable
      />
    </div>
  </div>

  <div class="deactivation">
    <div class="deactivation__title" v-if="formData.active.value">
      Заблокировать учетную запись
    </div>
    <div class="deactivation__title" v-else>Активировать учетную запись</div>
    <div class="deactivation-col">
      <p v-if="formData.active.value">
        Учетная запись будет заблокирована. <br />
        доступ к платформе будет полностью ограничен
      </p>
      <p v-else>
        Учетная запись будет активирована, <br />
        доступ к сервису будет полностью доступен
      </p>
      <Button
        v-if="formData.active.value"
        icon="pi-times"
        color="danger"
        @click="toggleActivation"
        :disabled="disabled"
        :style="{
          width: '206px',
        }"
      >
        Заблокировать
      </Button>
      <Button
        v-else
        icon="pi-check"
        color="primary"
        @click="toggleActivation"
        :disabled="disabled"
        :style="{
          width: '206px',
        }"
      >
        Активировать
      </Button>
    </div>
  </div>

  <h3 class="title">Роль учетной записи</h3>
  <div class="divider"></div>

  <div class="item">
    <FormSelect
      v-model="formData.roles.value"
      :items="roles"
      label="Роль"
      placeholder="Укажите роль"
      :disabled="disabled"
      :error-condition="formData.roles.error"
      searchable
    />
  </div>

  <h3 v-if="formData.password.value" class="title">Пароль учетной записи</h3>

  <h3 v-else class="title">Создайте пароль для учетной записи</h3>

  <div class="divider"></div>

  <div class="item item-password">
    <FormInput
      v-model="formData.password.value"
      type="password"
      label="Пароль"
      :validation="{}"
      :disabled="true"
      v-if="formData.password.value"
    />
    <Button
      variant="outlined"
      @click="accountsStore.showPasswordModal()"
      :disabled="disabled"
      v-if="!formData.password.value"
      :style="{
        width: '190px',
      }"
    >
      Создать пароль
    </Button>
    <Button
      variant="outlined"
      @click="accountsStore.showPasswordModal()"
      :disabled="disabled"
      v-if="formData.password.value"
      :style="{
        width: '206px',
      }"
    >
      Изменить пароль
    </Button>
  </div>

  <ChangePassword
    :showModal="accountsStore.isShowPasswordModal"
    :closeModal="accountsStore.hidePasswordModal"
    :changePassword="changePassword"
    :password="accountsStore.account.password.value"
  />
</template>

<script setup>
import {
  roles,
  services,
  updatePeriods,
  usernames,
} from "@/enums/enum-accounts";
import Button from "@/components/ui/Button/Button.vue";
import ChangePassword from "./ChangePassword.vue";
import { useAccountsStore } from "../../model/store/useAccountsStore";

const accountsStore = useAccountsStore();

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const formData = accountsStore.account;

const changePassword = (password) => {
  accountsStore.hidePasswordModal();
  accountsStore.setAccount({
    password,
  });
};

const toggleActivation = () => {
  accountsStore.setAccount({
    active: !accountsStore.account.active.value,
  });
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.form-row {
  display: flex;
  gap: 20px; /* Расстояние между элементами */
}

.item {
  flex: 1; /* Элементы будут растягиваться, чтобы занимать равное место */

  &-password {
    display: flex;
    align-items: center;
    gap: 24px;

    > div:first-child {
      flex-basis: 70%;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dee2e6;
  margin: 20px 0;
}

.deactivation {
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  background: #f1fdff;
  &__title {
    color: #3e445b;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }
  &-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      line-height: 20px;
    }
  }
}
</style>

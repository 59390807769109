<template>
  <div class="content">
    <Loading v-if="loading" style="height: 100vh" />
    <div v-else>
      <div class="table-header">
        <SearchInput
          placeholder="Поиск по Сервису, Учетной записи и Пользователю..."
        />
        <div class="button-container">
          <AccountsFilters
            :accounts="accounts"
            :updateFilteredItems="setFilteredItems"
          />

          <Button
            icon="pi-plus"
            @click="router.push(`/accounts/add`)"
            :style="{
              width: '270px',
            }"
            >Добавить учетную запись
          </Button>
        </div>
      </div>
      <Table
        name="accounts"
        :columns="columns"
        :data="filteredItems"
        :loading="loading"
        isSelectable
      >
        <template #actions="{ item }">
          <Button
            icon="pi-pencil"
            color="secondary"
            variant="outlined"
            rounded="sm"
            size="sm"
            @click="editHandler(item.id, item.service)"
          />
          <Button
            icon="pi-trash"
            color="danger"
            variant="outlined"
            rounded="sm"
            size="sm"
            @click="remove(accounts, item.id, 'accounts', '/accounts')"
          />
        </template>
        <template #dropdown-actions="{ item }">
          <li v-if="item.status.value === 'active'">Заблокировать</li>
          <li v-else>Разблокировать</li>
          <li>Изменить пароль</li>
        </template>
      </Table>
    </div>
  </div>
</template>

<script setup>
import Table from "@/components/table/Table";
import Loading from "@/components/Loading";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { USER_STATUSES } from "@/enums/enum-status";
import SearchInput from "@/components/table/SearchInput";
import Button from "@/components/ui/Button/Button.vue";
import accounts from "./model/consts/accounts";
import AccountsFilters from "./ui/components/AccountsFilters.vue";
import columns from "./model/consts/columns";
import { useAccountsStore } from "./model/store/useAccountsStore";

const loading = ref(false);
const accountsStore = useAccountsStore();
const router = useRouter();

const editHandler = (id, name) => {
  accountsStore.setEditMode();
  router.push(`/accounts/${id}?name=${name}`);
};

const filteredItems = ref(
  accounts.map((account) => ({
    ...account,
    status: USER_STATUSES[account.status],
  }))
);

const setFilteredItems = (items) => {
  filteredItems.value = items.map((item) => ({
    ...item,
    status: USER_STATUSES[item.status],
  }));
};

onMounted(() => {
  loading.value = false;
});
</script>

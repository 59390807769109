const accounts = [
  {
    id: 1,
    service: "Поликлиника №12",
    account: "Poliklinika_12",
    fio: "Куликов Иван Владимирович",
    created_at: "12 апреля 2024",
    last_login: "23 мая 2024",
    updated_at: "10 июня 2024",
    status: "active",
  },
  {
    id: 2,
    service: "1С, Предприятие",
    account: "1C_Organization",
    fio: "Перелеска Ирина Витальевна",
    created_at: "02 марта 2024",
    last_login: "11 апреля 2024",
    updated_at: "05 июня 2024",
    status: "inactive",
  },
  {
    id: 3,
    service: "МОБ ГБ Ирида",
    account: "Irida_142",
    fio: "Галинская Наталья Викторовна",
    created_at: "12 апреля 2024",
    last_login: "23 мая 2024",
    updated_at: "10 июня 2024",
    status: "active",
  },
  {
    id: 4,
    service: "МОБ СОШ Искра",
    account: "Iscra_24_Mob",
    fio: "Попова Елена Владимировна",
    created_at: "30 января 2024",
    last_login: "16 марта 2024",
    updated_at: "29 мая 2024",
    status: "active",
  },
  {
    id: 5,
    service: "1С, Предприятие",
    account: "1C_Organization45",
    fio: "Дик Игорь Александрович",
    created_at: "30 января 2024",
    last_login: "16 марта 2024",
    updated_at: "29 мая 2024",
    status: "active",
  },
  {
    id: 6,
    service: "Поликлиника №26",
    account: "Poliklinika_12",
    fio: "Куликов Иван Владимирович",
    created_at: "02 марта 2024",
    last_login: "11 апреля 2024",
    updated_at: "05 июня 2024",
    status: "inactive",
  },
  {
    id: 7,
    service: "МОБ ГБ Ирида",
    account: "Irida_142",
    fio: "Маркин Игорь Каримович",
    created_at: "12 апреля 2024",
    last_login: "23 мая 2024",
    updated_at: "10 июня 2024",
    status: "active",
  },
  {
    id: 8,
    service: "МОБ СОШ Искра",
    account: "Iscra_24_Mob",
    fio: "Марченко Андрей Анатольевич",
    created_at: "30 января 2024",
    last_login: "16 марта 2024",
    updated_at: "29 мая 2024",
    status: "active",
  },
  {
    id: 9,
    service: "Поликлиника №26",
    account: "Poliklinika_12",
    fio: "Ефимова Ольга Алексеевна",
    created_at: "17 августа 2024",
    last_login: "11 сентября 2024",
    updated_at: "05 июня 2024",
    status: "active",
  },
  {
    id: 10,
    service: "1С, Предприятие",
    account: "1C_Organization45",
    fio: "Влас Алексей Александрович",
    created_at: "02 марта 2024",
    last_login: "11 апреля 2024",
    updated_at: "05 июня 2024",
    status: "active",
  },
];

export default accounts;

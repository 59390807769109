<template>
  <Modalka
    v-if="employeesStore.isShowActivateAccount"
    :loading="false"
    @close="employeesStore.setShowActivateAccount(false)"
    :headerText="
      isActivation
        ? 'Активировать учетную запись?'
        : 'Заблокировать учетную запись?'
    "
  >
    <template #subtitle>
      <p class="info" v-if="isActivation">
        <b>Внимание!</b> Учетная запись будет активирована, доступ к сервису
        будет полностью доступен
      </p>
      <p class="info" v-else>
        <b>Внимание!</b> Учетная запись будет заблокирована, доступ к платформе
        будет полностью ограничен.
      </p>
    </template>
    <template #body>
      <img
        v-if="isActivation"
        src="../../model/assets/activate.svg"
        alt="Activation"
      />
      <img v-else src="../../model/assets/block.svg" alt="Deactivation" />
    </template>
    <template #footer>
      <Button
        color="secondary"
        @click="$emit('close')"
        :style="{
          width: '160px',
        }"
        >Отмена</Button
      >
      <Button
        :color="isActivation ? 'primary' : 'danger'"
        @click="$emit('add')"
        :style="{
          width: '206px',
        }"
        >{{ isActivation ? "Активировать" : "Заблокировать" }}</Button
      >
    </template>
  </Modalka>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import Modalka from "@/components/modals/Modalka.vue";
import { computed } from "vue";

const employeesStore = useEmployeesStore();
const isActivation = computed(
  () => employeesStore.isShowActivateAccount === "activate"
);
</script>

<style lang="scss" scoped>
.info {
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
img {
  display: block;
  width: 100%;
  height: 118px;
  text-align: center;
}
</style>

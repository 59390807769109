import { defineStore } from "pinia";

const maxStep = 3;

export const useRolesStore = defineStore("roles", {
  state: () => ({
    activeStep: 1,
    isShowDeleteCatalogModal: false,
    isShowDeleteEmployeeModal: false,
    isShowAddEmployeeModal: false,
    isEditMode: false,
    isShowFilters: false,
    isLoading: false,
  }),
  actions: {
    setActiveStep(payload) {
      this.activeStep = payload;
    },
    nextStep() {
      if (this.activeStep === maxStep) {
        this.activeStep = maxStep;
        return;
      }
      this.activeStep += 1;
    },
    prevStep() {
      if (this.activeStep === 1) {
        this.activeStep = 1;
        return;
      }
      this.activeStep -= 1;
    },
    setDeleteCatalogModal(payload) {
      this.isShowDeleteCatalogModal = payload;
    },
    setEditMode(payload) {
      this.isEditMode = payload;
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    setFilters(payload) {
      this.isShowFilters = payload;
    },
    setLoading(payload) {
      this.isLoading = payload;
    },
    setAddEmployeeModal(payload) {
      this.isShowAddEmployeeModal = payload;
    },
  },
});

<template>
  <div class="signin">
    <div class="signin__inner">
      <div class="signin__logo">
        <img src="@/assets/images/logo.svg" alt="logo" />
      </div>
      <div class="signin__form">
        <template v-if="!logginIn">
          <h1>
            Добро пожаловать в<br />
            <span>CTfind IDM</span>
          </h1>
          <form @submit.prevent="login">
            <FormInput
              v-model="form.email.value"
              :validation="form.email"
              type="email"
              label="Email"
            />
            <span
              v-if="form.email.touched && form.email.errors.required"
              class="field-group__error"
              >Поле 'E-mail' обязательно к заполнению!</span
            >
            <span
              v-else-if="form.email.touched && form.email.errors.validateEmail"
              class="field-group__error"
              >Неверный формат Email</span
            >
            <FormInput
              v-model="form.password.value"
              :validation="form.password"
              type="password"
              label="Пароль"
            />
            <span
              v-if="form.password.touched && form.password.errors.required"
              class="field-group__error"
              >Поле 'Пароль' обязательно к заполнению!</span
            >
            <div class="signin__submit">
              <Button rounded="sm" type="submit" :loading="loading"
                >Войти</Button
              >
            </div>
            <p>
              Мы следим за сохранностью приватности вашей информации. Пожалуйста
              ознакомьтесь с нашей
              <button type="button">Политикой конфиденциальности</button>.
            </p>
          </form>
        </template>
        <template v-else>
          <TwoFactor
            :email="form.email.value"
            :password="form.password.value"
            :code="code"
          />
        </template>
      </div>
    </div>
    <div class="signin__background">
      <img src="@/assets/images/login-back.jpg" alt="signin" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useForm } from "@/composables/form";
import ValidationsForm from "@/helpers/validations";
import { useAuthModule } from "@/store";
import TwoFactor from "@/components/TwoFactor";
import { AuthAPI } from "@/api/AuthAPI";
import notifications from "@/helpers/notifications";
import Button from "@/components/ui/Button/Button.vue";

const { validateEmail, required, minLength } = ValidationsForm;
const loading = ref(false);
const store = useAuthModule();
const logginIn = computed(() => store.logginIn);
const code = ref(null);

const { form } = useForm({
  email: {
    value: "",
    validators: { required, validateEmail },
  },
  password: {
    value: "",
    validators: { required, minLength: minLength(8) },
  },
});

const login = () => {
  loading.value = true;
  AuthAPI.login({
    email: form.email.value,
    password: form.password.value,
  })
    .then((response) => {
      store.setLogginIn(true);
      code.value = response.data.code;
    })
    .catch((err) => {
      notifications(err);
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style scoped lang="scss">
.signin {
  display: flex;
  height: 100vh;
  overflow: hidden;
  &__logo {
    position: absolute;
    top: 32px;
    left: 32px;
  }
  form {
    margin-top: 32px;
    p {
      color: #575e76;
      letter-spacing: 0.01em;
      font-size: 12px;
      line-height: 20px;
      margin-top: 24px;
      button {
        font-size: 12px;
        color: #5bc4d1;
        background: none;
        padding: 0;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          color: #378c97;
        }
      }
    }
  }
  &__reset {
    color: #8f97b1;
    letter-spacing: 0.01em;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    display: flex;
    margin-top: -10px;
    &:hover {
      color: #378c97;
    }
  }
  &__submit {
    margin-top: 32px;
    button {
      width: 100%;
    }
  }
  &__inner {
    max-width: 824px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fc;
  }
  &__background {
    flex: 1;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  &__form {
    max-width: 490px;
    width: 100%;
    padding: 0 20px;
    h1 {
      color: #aeb6cf;
      font-size: 36px;
      line-height: 44px;
      font-weight: 700;
      span {
        color: #727a93;
        display: block;
        font-size: 40px;
        line-height: 52px;
        font-weight: 700;
      }
    }
  }
}
@media (max-width: 1500px) {
  .signin__inner {
    max-width: 670px;
  }
}
@media (max-width: 1200px) {
  .signin__background {
    display: none;
  }
  .signin__inner {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .signin__form h1,
  .signin__form h1 span {
    font-size: 28px;
    line-height: 38px;
  }
  .signin form {
    margin-top: 15px;
  }
  .signin__logo {
    top: 20px;
    left: 20px;
  }
  .signin__form {
    margin-top: 30px;
  }
}
</style>

<template>
  <div class="form-container">
    <div class="top-line">
      <h3 class="title">Заполните общую информацию</h3>
    </div>

    <Form />

    <div class="item item--submit">
      <Button
        size="lg"
        variant="solid"
        color="secondary"
        @click="accountsStore.toggleEditMode()"
        :style="{
          width: '160px',
        }"
      >
        Отмена
      </Button>
      <Button
        size="lg"
        @click="add"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </div>
  </div>
</template>

<script setup>
import notifications from "@/helpers/notifications";
import Form from "./ui/components/AccountForm.vue";
import { useAccountsStore } from "./model/store/useAccountsStore";
import Button from "@/components/ui/Button/Button.vue";
import { AccountsAPI } from "@/api/AccountsAPI";
import { notify } from "@kyvg/vue3-notification";
import { onMounted } from "vue";

const accountsStore = useAccountsStore();

const add = async () => {
  if (!accountsStore.validateForm()) {
    return;
  }
  AccountsAPI.add(accountsStore.getValues)
    .then(() => {
      window.dispatchEvent(new CustomEvent("updateTable"));
      notify({
        type: "success",
        text: "Учетная запись успешно добавлена",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};

onMounted(() => {
  accountsStore.resetAccount();
});
</script>

<template>
  <div class="table-header">
    <SearchInput placeholder="Поиск по Названию или Владельцу..." />
    <div class="button-container">
      <RolesFilters :roles="roles" :updateFilteredItems="setFilteredItems" />
    </div>
  </div>
  <Table
    name="RolesList"
    :columns="columns"
    :data="filteredItems"
    :loading="loading"
    isSelectable
  >
    <template #actions="{ item }">
      <Button
        icon="pi-pencil"
        color="secondary"
        variant="outlined"
        rounded="sm"
        size="sm"
        @click="editHandler(item.id, item.name)"
      />
      <Button
        icon="pi-trash"
        color="danger"
        variant="outlined"
        rounded="sm"
        size="sm"
        @click="remove(roles, item.id, 'roles', '/roles')"
      />
    </template>
  </Table>
</template>

<script setup>
import Table from "@/components/table/Table";
import { ref, onMounted } from "vue";
import { remove } from "@/composables/actions";
import SearchInput from "@/components/table/SearchInput";
import { roles } from "../../../model/consts/rolesData";
import { columns } from "../../../model/consts/columns";
import Button from "@/components/ui/Button/Button.vue";
import RolesFilters from "../../components/RolesFilters.vue";
import { useRolesStore } from "../../../model/store/useRolesStore";
import { useRouter } from "vue-router";

const rolesStore = useRolesStore();
const router = useRouter();

const editHandler = (id, name) => {
  rolesStore.setEditMode(true);
  router.push(`/roles/${id}?name=${name}`);
};

const filteredItems = ref(roles);

const setFilteredItems = (items) => {
  filteredItems.value = items;
};

onMounted(() => {
  rolesStore.setLoading(false);
});
</script>

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import PrimeVue from "primevue/config";
import "primeicons/primeicons.css";
import "./assets/scss/main.scss";
import FormInput from "@/components/form/FormInput";
import FormButton from "@/components/form/FormButton";
import FormSelect from "@/components/form/FormSelect";
import FormSearch from "@/components/form/FormSearch";
import FormDate from "@/components/form/FormDate";
import Loading from "@/components/Loading";
import Notifications from "@kyvg/vue3-notification";
import vClickOutside from "click-outside-vue3";
import { createMetaManager } from "vue-meta";
import Tooltip from "primevue/tooltip";
import primeLocalisation from "@/enums/enum-localisation";

const pinia = createPinia();

createApp(App)
  .use(pinia)
  .use(router)
  .use(PrimeVue, {
    locale: primeLocalisation,
  })
  .use(VueAxios, axios)
  .use(Notifications)
  .use(vClickOutside)
  .use(createMetaManager())
  .directive("tooltip", Tooltip)
  .component("FormButton", FormButton)
  .component("FormInput", FormInput)
  .component("FormSelect", FormSelect)
  .component("FormSearch", FormSearch)
  .component("FormDate", FormDate)
  .component("Loading", Loading)
  .mount("#app");

<template>
  <div class="card">
    <div class="card__header">
      <div class="card__title">
        <h3 class="title">{{ fullName }}</h3>
        <p class="subtitle">Табельный номер: <span>12345678</span></p>
      </div>
    </div>
    <div class="card__body w-50">
      <EmployeeForm :disabled="!employeesStore.isEditMode" />
    </div>
    <div class="card__footer" v-if="employeesStore.isEditMode">
      <Button
        variant="solid"
        color="secondary"
        @click="employeesStore.toggleEditMode()"
        :style="{
          width: '160px',
        }"
      >
        Отмена
      </Button>
      <Button
        @click="editEmployee"
        :style="{
          width: '206px',
        }"
      >
        Сохранить
      </Button>
    </div>
  </div>
</template>

<script setup>
import EmployeeForm from "../components/EmployeeForm.vue";
import { computed } from "vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import Button from "@/components/ui/Button/Button.vue";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useRoute } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

const employeesStore = useEmployeesStore();
const fullName = computed(() => employeesStore.getFullName);

const route = useRoute();

const editEmployee = () => {
  if (!employeesStore.validateForm()) {
    return;
  }
  EmployeesAPI.update(employeesStore.getValues, route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь успешно изменен",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>

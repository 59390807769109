const primeLocalisation = {
  monthNames: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  // Days of the week
  dayNames: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  // Short names for days of the week
  dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  // Short names for months
  monthNamesShort: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ],
  // Today button text
  today: "Сегодня",
  // Clear button text
  clear: "Очистить",
  // Date format
  dateFormat: "dd MM yy",
  // First day of the week
  firstDayOfWeek: 1, // Monday
  // Week number
  weekHeader: "Нед",
  // Locale name
  localeName: "ru",
};

export default primeLocalisation;

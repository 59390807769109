<template>
  <Button
    size="sm"
    icon="pi-plus"
    @click="rolesStore.setAddEmployeeModal(true)"
    :style="{
      width: '254px',
    }"
    >Добавить пользователя
  </Button>

  <SideMenu
    @close="closeForm"
    v-if="rolesStore.isShowAddEmployeeModal"
    title="Добавить пользователя"
    width="804"
  >
    <template #body> <EmployeeForm /> </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        @click="closeForm"
        :style="{
          width: '170px',
        }"
      >
        Отмена
      </Button>
      <Button
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import Button from "@/components/ui/Button/Button.vue";
import EmployeeForm from "./EmployeeForm.vue";
import { useRolesStore } from "../../model/store/useRolesStore";

const rolesStore = useRolesStore();

const closeForm = () => {
  rolesStore.setAddEmployeeModal(false);
};
</script>

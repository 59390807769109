import { notify } from "@kyvg/vue3-notification";
import { defineStore } from "pinia";

export const useAccountsStore = defineStore("accounts", {
  state: () => ({
    isShowDeleteModal: false,
    isEditMode: false,
    isShowFilters: false,
    isShowPasswordModal: false,
    account: {
      name: { value: "", required: true, error: false },
      serviceId: { value: "", required: true, error: false },
      renewalPeriod: { value: "", required: true, error: false },
      employeeId: { value: "", required: true, error: false },
      active: { value: false, required: false, error: false },
      roles: { value: "", required: true, error: false },
      password: { value: "", required: true, error: false },
    },
  }),
  getters: {
    getValues(state) {
      const values = {};
      for (const key in state.account) {
        values[key] = state.account[key].value;
      }
      return values;
    },
  },
  actions: {
    hideDeleteModal() {
      this.isShowDeleteModal = false;
    },
    showDeleteModal() {
      this.isShowDeleteModal = true;
    },
    setEditMode() {
      this.isEditMode = true;
    },
    unsetEditMode() {
      this.isEditMode = false;
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    showFilters() {
      this.isShowFilters = true;
    },
    hideFilters() {
      this.isShowFilters = false;
    },
    showPasswordModal() {
      this.isShowPasswordModal = true;
    },
    hidePasswordModal() {
      this.isShowPasswordModal = false;
    },
    setAccount(payload) {
      for (const key in payload) {
        this.account[key].value = payload[key];
      }
    },
    resetAccount() {
      for (const key in this.account) {
        this.account[key].value = "";
        this.account[key].error = false;
      }
    },
    validateForm() {
      let unfilledItems = 0;
      const formData = this.account;

      for (const field in formData) {
        const fieldData = formData[field];

        if (fieldData.required && !fieldData.value) {
          fieldData.error = true;
          unfilledItems += 1;
        } else {
          fieldData.error = false;
        }
      }
      if (unfilledItems !== 0) {
        notify({
          type: "warn",
          text: `Не заполнено ${unfilledItems} пунктов`,
        });
        return false;
      }
      return true;
    },
  },
});

<template>
  <div class="table-header">
    <SearchInput
      placeholder="Поиск по ФИО пользователя, Подразделению или Должности..."
    />
    <div class="button-container">
      <AddEmployeeModal
        v-if="rolesStore.isEditMode"
        :is-show-modal="rolesStore.isShowAddEmployeeModal"
        @close="rolesStore.setAddEmployeeModal(false)"
      />
      <Button
        v-else
        icon="pi-pencil"
        variant="outlined"
        color="default"
        @click="rolesStore.toggleEditMode()"
        :active="rolesStore.isEditMode"
      />
    </div>
  </div>
  <Table name="employees" :columns="columns" :data="employees">
    <template #actions="{ item }">
      <Button
        v-if="rolesStore.isEditMode"
        icon="pi-trash"
        color="danger"
        variant="outlined"
        rounded="sm"
        size="sm"
        @click="remove(employees, item.id, 'employees', '/employees')"
      />
    </template>
  </Table>
</template>

<script setup>
import { EmployeesAPI } from "@/api/EmployeesAPI";
import SearchInput from "@/components/table/SearchInput.vue";
import Table from "@/components/table/Table.vue";
import Button from "@/components/ui/Button/Button.vue";
import AddEmployeeModal from "@/views/Roles/ui/components/AddEmployeeModal.vue";
import { useRolesStore } from "@/views/Roles/model/store/useRolesStore";
import { onMounted, ref } from "vue";

const rolesStore = useRolesStore();

const columns = {
  id: { value: "ID" },
  name: { value: "ФИО пользователя" },
  department_name: { value: "Подразделение" },
  position_name: { value: "Должность" },
};

const employees = ref([]);

const fetchData = () => {
  EmployeesAPI.getAll().then((res) => {
    employees.value = res.data.map((item) => ({
      ...item,
      department_name: item.department?.name,
      position_name: item.position?.name,
    }));
  });
};

onMounted(() => {
  fetchData();
  window.addEventListener("updateTable", fetchData);
});
</script>

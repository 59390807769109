export const catalog = [
  {
    id: 1,
    title: "Администраторы",
    items: [
      {
        id: 1,
        title: "Администратор ИБ",
        description:
          "Роль расширяет полномочия пользователя до Администратора ИБ",
      },
      {
        id: 2,
        title: "Базовый уровень доступа",
        description: "1С роль: Базовый уровень доступа",
      },
      {
        id: 3,
        title: "Веб-сервис для InRights",
        description: "1С роль: Веб-сервис для InRights",
      },
      {
        id: 4,
        title: "Владелец роли",
        description:
          "Роль назначена пользователю только, если он является владельцем хотя бы одной роли",
      },
      {
        id: 5,
        title: "Ответственный за ИС",
        description:
          "Роль назначена пользователю только, если он является ответственным хотя бы одной роли из ИС",
      },
      {
        id: 6,
        title: "Ответственный за подразделение",
        description:
          "Роль дает возможность просмотр пользователей и управление ролями в дочерних подразделениях",
      },
      {
        id: 7,
        title: "Офицер ИБ",
        description: "Роль расширяет полномочия пользователя до офицера ИБ",
      },
    ],
  },
  {
    id: 2,
    title: "Бухгалтеры",
    items: [
      {
        id: 1,
        title: "Администратор ИБ",
        description:
          "Роль расширяет полномочия пользователя до Администратора ИБ",
      },
      {
        id: 2,
        title: "Базовый уровень доступа",
        description: "1С роль: Базовый уровень доступа",
      },
      {
        id: 3,
        title: "Веб-сервис для InRights",
        description: "1С роль: Веб-сервис для InRights",
      },
    ],
  },
  {
    id: 3,
    title: "Владельцы ресурсов",
    items: [
      {
        id: 1,
        title: "Администратор ИБ",
        description:
          "Роль расширяет полномочия пользователя до Администратора ИБ",
      },
      {
        id: 2,
        title: "Базовый уровень доступа",
        description: "1С роль: Базовый уровень доступа",
      },
    ],
  },
  {
    id: 4,
    title: "ИБ",
    items: [
      {
        id: 1,
        title: "Администратор ИБ",
        description:
          "Роль расширяет полномочия пользователя до Администратора ИБ",
      },
      {
        id: 2,
        title: "Базовый уровень доступа",
        description: "1С роль: Базовый уровень доступа",
      },
      {
        id: 3,
        title: "Веб-сервис для InRights",
        description: "1С роль: Веб-сервис для InRights",
      },
      {
        id: 4,
        title: "Владелец роли",
        description:
          "Роль назначена пользователю только, если он является владельцем хотя бы одной роли",
      },
    ],
  },
];

export const roles = [
  {
    id: 1,
    title: "Администратор ИБ",
    description: "Роль расширяет полномочия пользователя до Администратора ИБ",
    items: [
      {
        id: 1,
        title: "Список сотрудников",
      },
      {
        id: 2,
        title: "Добавление сотрудника",
      },
      {
        id: 3,
        title: "Редактирование сотрудника",
      },
      {
        id: 4,
        title: "Редактирование правами доступа сотрудника",
      },
      {
        id: 5,
        title: "Добавление контакта",
      },
      {
        id: 6,
        title: "Удаление контакта",
      },
      {
        id: 7,
        title: "Редактирование сотрудника",
      },
      {
        id: 8,
        title: "Редактирование правами доступа сотрудника",
      },
    ],
  },
  {
    id: 2,
    title: "Базовый уровень доступа",
    description: "1С роль: Базовый уровень доступа",
    items: [
      {
        id: 1,
        title: "Список сотрудников",
      },
      {
        id: 2,
        title: "Добавление сотрудника",
      },
      {
        id: 3,
        title: "Редактирование сотрудника",
      },
      {
        id: 4,
        title: "Редактирование правами доступа сотрудника",
      },
      {
        id: 5,
        title: "Добавление контакта",
      },
    ],
  },
  {
    id: 3,
    title: "Веб-сервис для InRights",
    description: "1С роль: Веб-сервис для InRights",
    items: [
      {
        id: 1,
        title: "Список сотрудников",
      },
      {
        id: 2,
        title: "Добавление сотрудника",
      },
      {
        id: 3,
        title: "Редактирование сотрудника",
      },
    ],
  },
  {
    id: 4,
    title: "Владелец роли",
    description:
      "Роль назначена пользователю только, если он является владельцем хотя бы одной роли",
    items: [
      {
        id: 1,
        title: "Список сотрудников",
      },
      {
        id: 2,
        title: "Добавление сотрудника",
      },
      {
        id: 3,
        title: "Редактирование сотрудника",
      },
      {
        id: 4,
        title: "Редактирование правами доступа сотрудника",
      },
      {
        id: 5,
        title: "Добавление контакта",
      },
      {
        id: 6,
        title: "Удаление контакта",
      },
    ],
  },
];

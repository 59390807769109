export const roles = [
  {
    id: 1,
    name: "Системный администратор",
    source: "Функциональные роли ENUM",
    owner: "Куликов Иван Владимирович",
    department: "Техническая дирекция",
  },
  {
    id: 2,
    name: "Администратор ГО",
    source: "Базовый уровень доступа",
    owner: "Перелеска Ирина Витальевна",
    department: "Отдел кадров",
  },
  {
    id: 3,
    name: "Администратор ИБ",
    source: "Технические роли",
    owner: "Галинаска Наталья Викторовна",
    department: "Техническая дирекция",
  },
  {
    id: 4,
    name: "Администратор ИТ",
    source: "Базовый уровень доступа",
    owner: "Попова Елена Владимировна",
    department: "Отдел кадров",
  },
  {
    id: 5,
    name: "Администратор ТД",
    source: "Базовый уровень доступа",
    owner: "Дик Игорь Александрович",
    department: "Техническая дирекция",
  },
  {
    id: 6,
    name: "Администратор сети",
    source: "Функциональные роли ENUM",
    owner: "Куликов Иван Владимирович",
    department: "Бухгалтерия",
  },
  {
    id: 7,
    name: "Администратор ИТ",
    source: "Технические роли",
    owner: "Маркин Игорь Григорьевич",
    department: "Техническая дирекция",
  },
  {
    id: 8,
    name: "Сотрудник ИТ",
    source: "Базовый уровень доступа",
    owner: "Кривошеев Анатольевич",
    department: "Техническая дирекция",
  },
  {
    id: 9,
    name: "Сотрудник ГО",
    source: "Технические роли",
    owner: "Ефимова Олеся Алексеевна",
    department: "Техническая дирекция",
  },
  {
    id: 10,
    name: "Сотрудник ИБ",
    source: "Функциональные роли ENUM",
    owner: "Влас Алексей Иванович",
    department: "Бухгалтерия",
  },
];

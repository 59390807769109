<template>
  <div class="form">
    <div class="item">
      <FormSelect
        v-model="formData.value[0].user"
        :items="usernames"
        label="Пользователь"
        placeholder="Выберите пользователя"
        searchable
      />
    </div>
    <div class="item">
      <FormSelect
        v-model="formData.value[0].department"
        :items="departments"
        label="Подразделение"
        placeholder="Выберите пользователя"
        searchable
      />
    </div>
    <div class="item">
      <FormSelect
        v-model="formData.value[0].position"
        :items="positions"
        label="Должность"
        placeholder="Выберите пользователя"
        searchable
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { DepartmentsAPI } from "@/api/DepartmentsAPI";
import { PositionsAPI } from "@/api/PositionsAPI";
import { useFormStore } from "../../model/store/useFormStore";
import { usernames } from "@/enums/enum-accounts";

const formStore = useFormStore();

const formData = formStore.formData[3].employees;

const departments = ref([]);
const positions = ref([]);

onMounted(() => {
  DepartmentsAPI.getAll().then((res) => {
    departments.value = res.data;
  });
  PositionsAPI.getAll().then((res) => {
    positions.value = res.data;
  });
});
</script>
